import React from "react"
import Sensor, { displayNameForSensor } from "../../../models/sensor"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from "@mui/material"
import { MaxDifferenceCalculation, calculateMaxDifference, convertedDifferenceValue } from "../../../utilities/calculations"
import SampleUnit from "../../../models/units"
import moment from "moment"

interface Props {
  id: string
  selectedSensors: Sensor[]
  temperatureUnit: SampleUnit
  timezone: string
}

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(event)": {
    backgroundColor: "#AAAAAA",
  },
}))

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#7F7F7F"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 6
  },
  "&:not(:last-child)": {
    borderRight: "1px solid black"
  }
}))

const DifferentialSummaryTableView = (props: Props) => {
  const maxDifferenceData: MaxDifferenceCalculation | null = calculateMaxDifference(props.selectedSensors)
  if (!maxDifferenceData) {
    return (
      <></>
    )
  }

  function createData(name: string, date: string, difference: number, minSensorName: string, maxSensorName: string) {
    return { name, date, difference, minSensorName, maxSensorName}
  }

  const rows = [
    createData(
      "Maximum Difference", 
      moment(maxDifferenceData.time).format("YYYY/MM/DD HH:mm"),
      maxDifferenceData.maxDifference, 
      displayNameForSensor(maxDifferenceData.minSensor),
      displayNameForSensor(maxDifferenceData.maxSensor)
    )
  ]
  return (
    <TableContainer id={props.id} component={Paper} sx={{
      width: "80%",
      overflow: "hidden"
    }}>
      <Table sx={{
        border: "2px solid black"
      }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>

            </StyledTableCell>
            <StyledTableCell>
              Time
            </StyledTableCell>
            <StyledTableCell>
              {`Difference (º${props.temperatureUnit.toUpperCase()})`}
            </StyledTableCell>
            <StyledTableCell>
              Min Sensor
            </StyledTableCell>
            <StyledTableCell>
              Max Sensor
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row" sx={{
                backgroundColor: "#7F7F7F",
                borderRight: "1px solid black",
              }}>
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{moment(row.date).tz(props.timezone).format("MM/DD/YY HH:mm")}</StyledTableCell>
              <StyledTableCell align="right">{convertedDifferenceValue(row.difference, props.temperatureUnit)}</StyledTableCell>
              <StyledTableCell align="right">{row.minSensorName}</StyledTableCell>
              <StyledTableCell align="right">{row.maxSensorName}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DifferentialSummaryTableView
