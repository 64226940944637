import { Box, Button, Tab, Tabs, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import Project from "../../models/project"
import { useAppSelector } from "../../store/hooks"
import ProjectListView from "./ProjectListView"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CreateProjectDialog from "./CreateProjectDialog"

interface Props {
  onProjectSelect: (project: Project) => void
}

const ProjectLoadingContainer = (props: Props) => {
  const reduxProjects = useAppSelector(state => state.project.projects)

  const [projects, setProjects] = useState<Project[]>([])

  const [archivedProjects, setArchivedProjects] = useState<Project[]>([])

  const [showingArchived, setShowingArchived] = useState(false)

  const [sortOrder, setSortOrder] = useState("name")

  const [newProjectDialogOpen, setNewProjectDialogOpen] = useState(false)

  const sortedForOrder = (projectList: Project[]) => {
    return projectList.sort((a: Project, b: Project) => {
      if (sortOrder === "name") {
        if (a.name < b.name) {
          return -1
        } else if (a.name > b.name) {
          return 1
        } else {
          return 0
        }
      } else {
        if (a.updatedAt > b.updatedAt) {
          return -1
        } else if (a.updatedAt < b.updatedAt) {
          return 1
        } else {
          return 0
        }
      }
    })
  }

  useEffect(() => {
    const unarchivedProjects: Project[] = []
    const archivedProjects: Project[] = []
    reduxProjects.forEach((project) => {
      if (project.userInfo.isArchived) {
        archivedProjects.push(project)
      } else {
        unarchivedProjects.push(project)
      }
    })
    setProjects(sortedForOrder(unarchivedProjects))
    setArchivedProjects(sortedForOrder(archivedProjects))
  }, [reduxProjects, sortOrder])

  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    if (selectedTab === 0) {
      setSortOrder("name")
    } else {
      setSortOrder("recent")
    }
  }, [selectedTab])

  return (
    <Box sx={{
      height: "100%",
      padding: 0
    }}>
      <Box sx={{
        width: "100%",
        height: "calc(100% - 50px)",
        overflow: "scroll",
        padding: 0,
        paddingBottom: {
          xxs: "86px",
          xs: "86px",
          sm: "86px",
          md: "0px"
        }
      }}>
        <Typography variant="subtitle2" sx={{
          padding: "0",
          marginTop: "24px",
          paddingLeft: "24px",
        }}>
          Projects
        </Typography>
        <Tabs 
          variant="fullWidth" 
          value={selectedTab} 
          onChange={(event, newValue) => setSelectedTab(newValue)}
          sx={{
            margin: "24px 24px 0px 24px"
          }}
        >
          <Tab label="Name" value={0} />
          <Tab label="Recent" value={1} />
        </Tabs>
        <ProjectListView 
          projects={projects} 
          onProjectSelect={props.onProjectSelect}
        />
        {archivedProjects.length > 0 &&
          <>
            <Button 
              fullWidth={true} 
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                padding: "6px 12px 6px 24px",
                marginTop: "6px",
              }}
              onClick={() => {
                setShowingArchived(!showingArchived)
              }}
            >
              <Typography variant="subtitle2" sx={{
                padding: "0",
              }}>
                Archived
              </Typography>
              {!showingArchived &&
                <ChevronRightIcon sx={{
                  width: "18px",
                  height: "18px",
                  color: "rgba(0, 0, 0, 0.5)"
                }} />
              }
              {showingArchived &&
                <ExpandMoreIcon sx={{
                  width: "18px",
                  height: "18px",
                  color: "rgba(0, 0, 0, 0.5)"
                }} />
              }
            </Button>
            <Box sx={{
              display: showingArchived ? "visible" : "none"
            }}>
              <ProjectListView 
                projects={archivedProjects}
                onProjectSelect={props.onProjectSelect}
              />
            </Box>
          </>
        }
      </Box>
      <Button 
        fullWidth={true}
        variant="contained"
        sx={{
          height: "50px",
          justifyContent: "center",
          alignItems: "center",
          padding: "6px 12px 6px 24px",
          borderRadius: "0px",
        }}
        onClick={() => setNewProjectDialogOpen(true)}
      >
        <Typography variant="subtitle1" sx={{
          color: "white",
          padding: "0",
          textTransform: "none"
        }}>
        New Project
        </Typography>
      </Button>
      <CreateProjectDialog 
        open={newProjectDialogOpen}
        onClose={() => setNewProjectDialogOpen(false)}
        onProjectCreate={(project) => {
          setNewProjectDialogOpen(false)
          props.onProjectSelect(project)
        }}
      />
    </Box>
  )
}

export default ProjectLoadingContainer
