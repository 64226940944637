import { Box, Popover, Toolbar } from "@mui/material"
import React, { useEffect, useState } from "react"
import PlacementDetailsContainerView from "../placement/PlacementDetailsContainerView"
import PlacementListView from "../placements/PlacementListView"
import ProjectLoadingContainer from "../projects/ProjectLoadingContainer"
import EmptyIconView from "../reusable/EmptyIconView"
import NavigationBarView from "../reusable/NavigationBarView"
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import ProjectSettingsView from "../projects/ProjectSettingsView"
import { useAppSelector } from "../../store/hooks"

const TemperatureAndMaturityMonitoringLandingView = () => {
  const [projectSettings, setProjectSettings] = useState<HTMLElement | null>(null)
  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>(undefined)
  const loadedProjects = useAppSelector(state => state.project.projects)
  let selectedProject = undefined
  if (selectedProjectId !== undefined) {
    selectedProject = loadedProjects.find(p => p.id === parseInt(selectedProjectId))
  }

  const togglePopover = (event: React.MouseEvent<HTMLElement>) => {
    setProjectSettings(event.currentTarget)
  }

  const popoverIsOpen = Boolean(projectSettings)
  const popoverId = popoverIsOpen ? "placement-specs" : undefined

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const projectId = (location.pathname.split("/projects/")[1] || undefined)
    setSelectedProjectId(projectId)
  }, [location])

  return (
    <Box display="flex" flexDirection="column" alignItems="stetch" sx={{
      height: "100%",
      overflow: "hidden"
    }}>
      <Toolbar />
      <NavigationBarView 
        title={selectedProject?.name || "COMMAND Center Concrete"}
        backButtonTitle={ selectedProjectId ? "All Projects" : undefined}
        onBack={() => {
          navigate("/")
        }}
        projectSettingsButtonTitle={ selectedProjectId ? "Settings" : undefined }
        onProjectSettingsClicked={togglePopover}
      />
      <Box display="flex" sx={{
        overflow: "hidden",
        height: "100%"
      }} flexDirection="row" alignItems="stretch" position="relative" flexGrow={1}>
        <Box flexGrow={1} flexShrink={0} sx={{
          height: "100%",
          overflow: "hidden",
          maxWidth: "336px",
          minWidth: "336px",
          width: "336px",
          borderRight: "1px solid gray"
        }}>
          <Routes>
            <Route path="/" element={
              <ProjectLoadingContainer 
                onProjectSelect={ (project) => {
                  navigate(`/projects/${project.id}`)
                }} />
            } />
            <Route path="/projects/:projectId" element={
              <PlacementListView
                onPlacementSelect={(placement) => {
                  navigate(`/projects/${placement.projectId}/placements/${placement.id}`)
                }}
              />
            } />
            <Route path="/projects/:projectId/placements/:placementId" element={
              <PlacementListView
                onPlacementSelect={(placement) => {
                  navigate(`/projects/${placement.projectId}/placements/${placement.id}`)
                }}
              />
            } />
            <Route path="*" element={
              <Navigate to="/" />
            }/>
          </Routes>
        </Box>
        <Box display="flex" sx={{
          overflow: "hidden"
        }} flexGrow={4}>
          <Routes>
            <Route path="/" element={
              <EmptyIconView helperText="Select a project from the menu on the left to see it here." />
            }/>
            <Route path="/projects/:projectId" element={
              <EmptyIconView helperText="Select a placement from the menu on the left to see it here." />
            }/>
            <Route path="/projects/:projectId/placements/:placementId" element={
              <PlacementDetailsContainerView />  
            } />
          </Routes>
        </Box>
      </Box>
      {selectedProject &&
        <Popover 
          id={popoverId}
          open={popoverIsOpen}
          anchorEl={projectSettings}
          onClose={() => { setProjectSettings(null) }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <ProjectSettingsView project={selectedProject} />
        </Popover>
      }
    </Box>
  )
}

export default TemperatureAndMaturityMonitoringLandingView
