import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Project from "../../models/project"
import { fetchProjects } from "../../network/network"

export interface UserState {
  projects: Project[],
  loading: boolean
}

const initialState: UserState = {
  projects: [],
  loading: false
}

export const loadProjects = createAsyncThunk(
  "project/fetchProjects",
  async (token: string) => {
    return await fetchProjects(token)
  }
)

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    loadProjcetsSilently: (state, param) => {
      state.loading = false
      state.projects = param.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadProjects.pending, (state) => {
        state.loading = true
      })
      .addCase(loadProjects.fulfilled, (state, action) => {
        state.loading = false
        state.projects = action.payload
      })
      .addCase(loadProjects.rejected, (state) => {
        state.loading = false
      })
      .addCase(loadProjects.typePrefix, (state) => {
        state.loading = true
      })
  }
})

export const { loadProjcetsSilently } = projectSlice.actions
export default projectSlice.reducer
