import { Box, List, ListItem, Typography } from "@mui/material"
import React from "react"

interface Props {
  mainAlertTitle: string
  mainAlertEnabled: boolean
  mainAlertDescription: string
  mainAlertValue: string

  secondaryAlertTitle: string
  secondaryAlertEnabled: boolean
  secondaryAlertDescription: string
  secondaryAlertValue: string
}

const AlertSettingSection = (props: Props) => {
  return (
    <List sx={{
      padding: "0px 0px",
      marginTop: "12px",
    }}>
      <ListItem sx={{
        width: "100%"
      }}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
          width: "100%",
          padding: 0,
          margin: 0
        }}>
          <Typography variant="body1" sx={{fontWeight: "bold"}}>
            {props.mainAlertTitle}
          </Typography>
          <Typography variant="body1" color={props.mainAlertEnabled ? "green" : "gray"} sx={{
            fontWeight: props.mainAlertEnabled ? "bold" : "normal"
          }}>
            {props.mainAlertEnabled ? "ON" : "OFF"}
          </Typography>
        </Box>
      </ListItem>
      {props.mainAlertEnabled &&
        <>
          <ListItem sx={{
            width: "100%"
          }}>
            <Box display="flex" flexDirection="row" justifyContent={"space-between"} alignItems="top" sx={{
              width: "100%",
              padding: 0,
              margin: 0
            }}>
              <Typography variant="body2" sx={{
                maxWidth: "80%"
              }}>
                {props.mainAlertDescription}
              </Typography>
              <Typography variant="body1" color="gray">
                {props.mainAlertValue}
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
              width: "100%",
              padding: 0,
              margin: 0
            }}>
              <Typography variant="body1" sx={{fontWeight: "bold"}}>
                {props.secondaryAlertTitle}
              </Typography>
              <Typography variant="body1" color={props.secondaryAlertEnabled ? "green" : "gray"} sx={{
                fontWeight: props.secondaryAlertEnabled ? "bold" : "normal"
              }}>
                {props.secondaryAlertEnabled ? "ON" : "OFF"}
              </Typography>
            </Box>
          </ListItem>
          {props.secondaryAlertEnabled &&
            <ListItem sx={{
              width: "100%"
            }}>
              <Box display="flex" flexDirection="row" justifyContent={"space-between"} alignItems="top" sx={{
                width: "100%",
                padding: 0,
                margin: 0
              }}>
                <Typography variant="body2" sx={{
                  maxWidth: "80%"
                }}>
                  {props.secondaryAlertDescription}
                </Typography>
                <Typography variant="body1" color="gray">
                  {props.secondaryAlertValue}
                </Typography>
              </Box>
            </ListItem>
          }
        </>
      }
    </List>
  )
}

export default AlertSettingSection