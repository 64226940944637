export const capitalize = (stringToCapitalize: string) => {
  return stringToCapitalize[0].toUpperCase() + stringToCapitalize.substring(1).toLowerCase()
}

export const uniqueString = (text: string, existingStrings: string[], attempt = 1): string => {
  const stringToTest = attempt > 1 ? text + " (" + attempt + ")" : text

  if (existingStrings.map((string) => string.toLowerCase()).indexOf(stringToTest.toLowerCase()) > -1) {
    return uniqueString(text, existingStrings, attempt + 1)
  } else {
    return stringToTest
  }
}