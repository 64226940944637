import React from "react"
import { Box, Chip, ListItem, NativeSelect, Typography } from "@mui/material"
import { fetchAllProjectParticipants, ProjectParticipant } from "./ProjectSettingsView"
import Project from "../../models/project"
import { useAuth } from "../auth/AuthProvider"
import { useAppSelector } from "../../store/hooks"
import { deleteInvitation, deletePermission, updatePermission } from "../../network/network"

interface Props {
  project: Project
  participant: ProjectParticipant
  onRemove: (participants: ProjectParticipant[]) => void
}

const ProjectParticipantView = (props: Props) => {
  let participationLabel = ""
  if (props.participant.participation === "owner") {
    participationLabel = "Owner"
  } else if (props.participant.participation === "readonly") {
    participationLabel = "Limited"
  } else if (props.participant.participation === "pending") {
    participationLabel = "Pending"
  } else {
    participationLabel = "Full Access"
  }

  const [access, setAccess] = React.useState(props.participant.participation === "owner" ? "readwrite" : props.participant.participation === "readonly" ? "readonly" : "readwrite")
  const [isUpdatingAccess, setIsUpdatingAccess] = React.useState(false)

  const accessToken = useAppSelector(state => state.user.token)
  const { user } = useAuth()

  const isUserReadOnly = props.project.userInfo.permission === "readonly"
  const canEdit = props.participant.userId !== null && props.participant.participation !== "owner" && props.project.userInfo.permission !== "readonly" && props.participant.userId !== user?.identifier && !isUserReadOnly
  const canDelete = props.participant.userId == null && props.participant.participation !== "owner" && props.participant.userId !== user?.identifier && !isUserReadOnly


  const updateAccess = async (access: string) => {
    if (!accessToken || !props.project.id || !props.participant.userId) {
      return
    }

    if (access == "readonly" || access == "readwrite") {
      setAccess(access)
      setIsUpdatingAccess(true)

      try {
        await updatePermission(accessToken, props.project.id, props.participant.userId, access)
      } catch (error) {
        console.error(error)
        setAccess(props.participant.participation === "owner" ? "readwrite" : props.participant.participation === "readonly" ? "readonly" : "readwrite")
        alert("Failed to update access.")
      }

      setIsUpdatingAccess(false)
    } else if (access == "none" && confirm(`This will remove '${props.participant.email}' from the project. Are you sure?`)) {
      setIsUpdatingAccess(true)

      try {
        await deletePermission(accessToken, props.project.id, props.participant.userId)
        const participants = await fetchAllProjectParticipants(accessToken, props.project.id)
        props.onRemove(participants)
      } catch (error) {
        console.error(error)
        alert("Failed to remove participant.")
      }

      setIsUpdatingAccess(false)
    }
  }

  const deletePendingInvitation = async () => {
    if (!accessToken) {
      return
    }

    if (!confirm(`Are you sure you want to remove '${props.participant.email}'?`)) {
      return
    }
  
    setIsUpdatingAccess(true)
  
    try {
      await deleteInvitation(accessToken, props.project.id, props.participant.email)
      const participants = await fetchAllProjectParticipants(accessToken, props.project.id)
      props.onRemove(participants)
    } catch (error) {
      console.error(error)
      alert("Failed to remove invitation.")
    }
    
    setIsUpdatingAccess(false)
  }

  return (
    <ListItem sx={{
      width: "100%",
      paddingLeft: "0px",
      paddingRight: "0px"
    }}>
      <Box 
        display="flex" 
        flexDirection="row" 
        alignItems="center" 
        justifyContent="space-between"
        sx={{
          border: "1px solid #ccc",
          borderRadius: "24px",
          height: "32px",
          padding: "0px 0px 0px 12px",
          width: "100%"
        }}
      >
        <Typography sx={{
          marginRight: "24px",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}>
          {props.participant.email}
        </Typography>
        {canEdit &&
          <NativeSelect color="primary" variant="filled" disableUnderline sx={{
            textAlignLast: "center",
            height: "100%",
            paddingLeft: "12px",
            fontSize: "14px",
            backgroundColor: "rgba(0, 0, 0, 0.08)",
            borderRadius: "24px"
          }}
          value={access}
          onChange={(event) => {
            updateAccess(event.target.value)
          }}
          disabled={isUpdatingAccess}
          >
            <option value="readwrite">Full Access</option>
            <option value="readonly">Limited</option>
            <option value="none">No Access</option>
          </NativeSelect>
        }
        {!canEdit && !canDelete &&
          <Chip label={participationLabel}/>
        }
        {!canEdit && canDelete &&
          <Chip label={participationLabel} onDelete={deletePendingInvitation} disabled={isUpdatingAccess}/>
        }
      </Box>
    </ListItem>
  )
}

export default ProjectParticipantView