import React, { useState } from "react"
import Project from "../../models/project"
import Placement from "../../models/placement"
import Sensor, { earliestPlacementDate } from "../../models/sensor"
import { Box, Tab, Tabs } from "@mui/material"
import TemperatureChart from "../charts/TemperatureChart"
import TemperatureDifferenceChart from "../charts/TemperatureDifferenceChart"
import MaturityChart from "../charts/MaturityChart"
import StrengthChart from "../charts/StrengthChart"
import ChartDateSelectionView from "./ChartDateSelectionView"
import { maximumSampleDate } from "../../utilities/samples"
import moment, { Moment } from "moment"
import { useIsDesktop } from "../../utilities/environment"

interface Props {
  project: Project
  placement: Placement
  selectedSensors: Sensor[]
  colorMap: Map<number, string>
  startDate: moment.Moment
  endDate: moment.Moment
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <Box
      flexDirection="column"
      sx={{
        width: "100%",
        height: "100%",
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </Box>
  )
}

const ChartTabs = (props: Props) => {
  if (props.selectedSensors.length === 0) {
    return (
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-around" sx={{
        width: "100%",
        height: "100%"
      }}>
        Select one or more sensors to view their data.
      </Box>
    )
  }

  const [selectedTab, setSelectedTab] = useState(0)

  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(0)

  const earliestSensorPlacementDate: Moment | null = earliestPlacementDate(props.selectedSensors)
  const maximumSensorSampleDate: Moment | null = maximumSampleDate(props.selectedSensors)

  if (!earliestSensorPlacementDate || !maximumSensorSampleDate) {
    return (
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-around" sx={{
        width: "100%",
        height: "100%"
      }}>
        No data available for selected sensors.
      </Box>
    )
  }

  const [textFieldStartDate, setTextFieldStartDate] = useState<moment.Moment>(earliestSensorPlacementDate)
  const [textFieldEndDate, setTextFieldEndDate] = useState<moment.Moment>(maximumSensorSampleDate)

  const chartStartDate = selectedDateRangeOption === 0 ? earliestSensorPlacementDate : textFieldStartDate ?? earliestSensorPlacementDate
  const chartEndDate = selectedDateRangeOption === 0 ? maximumSensorSampleDate : textFieldEndDate ?? maximumSensorSampleDate
  const isDesktop = useIsDesktop()
  const totalHeight = isDesktop ? "100%" : "1000px"
  const preferredChartHeight = selectedTab === 0 ? "876px" : "100dvh"

  return (
    <Box sx={{
      padding: "12px",
      flexGrow: 1,
      height: totalHeight,
    }}>
      {props.placement.maturityMethod.type !== "disabled" &&
        <Tabs 
          variant="fullWidth" 
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
        >
          <Tab label="Temperature" value={0} />
          <Tab label="Maturity" value={1} />
          {props.placement.maturityMethod.curveFit.type !== "disabled" &&
            <Tab label="Strength" value={2} />
          }
        </Tabs>
      }
      <Box sx={{
        height: isDesktop ? "calc(100% - 124px)" : preferredChartHeight
      }}>
        <ChartDateSelectionView 
          selectedTab={selectedDateRangeOption} 
          onDateRangeChange={(start, end) => {
            setTextFieldEndDate(end.tz(props.placement.timeZone))
            const newStartDate = start.tz(props.placement.timeZone)
            setTextFieldStartDate(newStartDate)
          }}
          minStartDate={earliestSensorPlacementDate}
          maxEndDate={maximumSensorSampleDate}
          onTabChange={(tab: number) => {
            setSelectedDateRangeOption(tab)
            setTextFieldEndDate(maximumSensorSampleDate)
            setTextFieldStartDate(earliestSensorPlacementDate)
          }} 
        />
        <TabPanel value={selectedTab} index={0}>
          <Box sx={{
            width: "100%",
            height: props.selectedSensors.length > 1 ? "60%" : "100%",
            padding: "12px"
          }}>
            <TemperatureChart 
              forReport={false}
              startDate={chartStartDate}
              endDate={chartEndDate}
              sensors={props.selectedSensors}
              temperatureUnit={props.placement.temperatureUnit}
              colorMap={props.colorMap}
              timezone={props.placement.timeZone}
              min={props.placement.temperatureLimit?.minimumTemperature ?? undefined}
              max={props.placement.temperatureLimit?.maximumTemperature ?? undefined}
            />
          </Box>
          {props.selectedSensors.length > 1 &&
            <Box flex={1} sx={{
              width: "100%",
              height: "40%",
              padding: "12px"
            }}>
              <TemperatureDifferenceChart
                forReport={false}
                sensors={props.selectedSensors}
                temperatureUnit={props.placement.temperatureUnit}
                colorMap={props.colorMap}
                start={chartStartDate}
                end={chartEndDate}
                placement={props.placement}
                threshold={props.placement.temperatureLimit?.difference ?? undefined}
              />
            </Box>
          }
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Box sx={{
            width: "100%",
            height: "100%",
            padding: "12px"
          }}>
            <MaturityChart
              startDate={chartStartDate}
              endDate={chartEndDate} 
              sensors={props.selectedSensors} 
              placement={props.placement} 
              colorMap={props.colorMap} 
            />
          </Box>
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <Box sx={{
            width: "100%",
            height: "100%",
            padding: "12px"
          }}>
            <StrengthChart 
              sensors={props.selectedSensors} 
              placement={props.placement} 
              colorMap={props.colorMap} 
              startDate={chartStartDate}
              endDate={chartEndDate}
            />
          </Box>
        </TabPanel>
      </Box>
    </Box>
  )
}

export default ChartTabs
