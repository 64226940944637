import { ArrowBack } from "@mui/icons-material"
import { Box, IconButton, Tab, Tabs, Typography } from "@mui/material"
import React, { useState } from "react"
import AlertsView from "./AlertsView"
import AlertRecipientsView from "./AlertRecipientsView"
import AlertLogsView from "./AlertLogsView"

interface Props {
  projectId: number
  placementId: number
  onBack: () => void
}

const AlertContainerView = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <Box display="flex" flexDirection="column" sx={{
      width: "400px",
      maxWidth: "100%",
      padding: "12px"
    }}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <IconButton onClick={props.onBack}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5">
          Alerts
        </Typography>
      </Box>
      <Tabs 
        variant="fullWidth" 
        value={selectedTab} 
        onChange={(event, newValue) => setSelectedTab(newValue)}
        sx={{
          margin: "12px 12px 24px 12px"
        }}
      >
        <Tab label="Settings" value={0} />
        <Tab label="Recipients" value={1} />
        <Tab label="Logs" value={2} />
      </Tabs>
      {selectedTab === 0 &&
        <AlertsView projectId={props.projectId} placementId={props.placementId} />
      }
      {selectedTab === 1 &&
        <AlertRecipientsView projectId={props.projectId} placementId={props.placementId} />
      } 
      {selectedTab === 2 &&
        <AlertLogsView projectId={props.projectId} placementId={props.placementId} />
      }
    </Box>
  )
}

export default AlertContainerView
