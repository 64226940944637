import { Box, Tab, Tabs, styled } from "@mui/material"
import React, { useEffect, useState } from "react"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment"
import { useIsDesktop } from "../../utilities/environment"

interface Props {
  selectedTab: number
  onTabChange: (tab: number) => void
  minStartDate: moment.Moment
  maxEndDate: moment.Moment
  onDateRangeChange: (start: moment.Moment, end: moment.Moment) => void
}

const StyledInput = styled("input")(({ theme }) => `
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 40px;
  background: ${theme.palette.secondary};
`)

const ChartDateSelectionView = (props: Props) => {
  const [customRangeEdited, setCustomRangeEdited] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<moment.Moment>(props.minStartDate)
  const [endDate, setEndDate] = useState<moment.Moment>(props.maxEndDate)

  const customRangeEnabled = props.selectedTab === 1

  if (props.selectedTab == 1 && startDate !== null && startDate.isValid()) {
    props.onDateRangeChange(startDate, endDate)
  }

  const isDesktop = useIsDesktop()
  const flexDirection = isDesktop ? "row" : "column"
  const width = isDesktop ? "50%" : "100%"

  useEffect(() => {
    if (!customRangeEdited) {
      setStartDate(props.minStartDate)
      setEndDate(props.maxEndDate)
    }
  }, [props.minStartDate, props.maxEndDate])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box display="flex" flexDirection={{flexDirection}} justifyContent="space-between" alignItems="center">
        <Tabs value={props.selectedTab} onChange={(event, newValue) => {
          props.onTabChange(newValue)
        }}>
          <Tab label="Since Placement" value={0} />
          <Tab label="Custom Range" value={1} />
        </Tabs>

        <Box display="flex" flexDirection="row" justifyContent="space-around" alignItems="center" sx={{
          width: width
        }}>
          <StyledInput type="datetime-local" onChange={(event) => {
            setCustomRangeEdited(true)
            setStartDate(moment(event.target.value))
          }} disabled={!customRangeEnabled} 
          value={startDate?.format(moment.HTML5_FMT.DATETIME_LOCAL)}
          min={props.minStartDate?.format(moment.HTML5_FMT.DATETIME_LOCAL)}
          max={props.maxEndDate?.format(moment.HTML5_FMT.DATETIME_LOCAL)}/>
          <span>&nbsp;to&nbsp;</span>
          <StyledInput type="datetime-local" onChange={(event) => {
            setCustomRangeEdited(true)
            setEndDate(moment(event.target.value))
          }} disabled={!customRangeEnabled}
          value={endDate?.format(moment.HTML5_FMT.DATETIME_LOCAL)}
          min={startDate?.format(moment.HTML5_FMT.DATETIME_LOCAL)}
          max={props.maxEndDate?.format(moment.HTML5_FMT.DATETIME_LOCAL)}/>
        </Box>
      </Box>
    </LocalizationProvider>
  )
}

export default ChartDateSelectionView
