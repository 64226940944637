import React, { useState } from "react"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputBase, Tab, Tabs } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import * as EmailValidator from "email-validator"
import { createInvitation } from "../../network/network"
import { useAppSelector } from "../../store/hooks"
import { fetchAllProjectParticipants, ProjectParticipant } from "./ProjectSettingsView"
import { logInviteSent } from "../../utilities/analytics"

interface AddParticipantDialogProps {
  open: boolean
  onClose: (participants: ProjectParticipant[] | null) => void
  projectId: number
  participants: ProjectParticipant[]
}

const AddParticipantDialog = (props: AddParticipantDialogProps) => {
  const accessToken = useAppSelector(state => state.user.token)

  const [fullAccess, setFullAccess] = useState(true)
  const [creatingParticipant, setCreatingParticipant] = useState(false)
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const createParticipant = async () => {
    if (props.participants.find(p => p.email === email)) {
      setErrorMessage("Participant already exists.")
      return
    }

    if (!accessToken) {
      return
    }

    setCreatingParticipant(true)

    if (!EmailValidator.validate(email)) {
      setErrorMessage("Invalid email address.")
      setCreatingParticipant(false)
      return
    }

    if (props.participants.find(p => p.email.toLowerCase() === email.toLowerCase())) {
      setErrorMessage("Participant already exists.")
      setCreatingParticipant(false)
      return
    }

    setErrorMessage(null)

    try {
      await createInvitation(accessToken, props.projectId, email, fullAccess ? "readwrite" : "readonly")
      const participants = await fetchAllProjectParticipants(accessToken, props.projectId)

      logInviteSent(props.projectId, fullAccess ? "full" : "limited")

      props.onClose(participants)
    } catch (e) {
      console.error(e)
      setErrorMessage("Failed to invite participant.")
    }

    setCreatingParticipant(false)
  }

  const closeDialog = () => {
    if (creatingParticipant) {
      return
    }

    props.onClose(null)
  }

  return (
    <Dialog open={props.open} onClose={closeDialog} maxWidth="sm" fullWidth={true} sx={{ textAlign: "center" }}>
      <DialogTitle variant="h5" sx={{ fontSize: "18px" }}>Invite Project Participant</DialogTitle>
      <DialogContent sx={{ alignContent: "center" }}>
        <Box sx={{ textAlign: "center", maxWidth: "400px", margin: "auto"  }}>
          <Tabs
            variant="fullWidth" 
            value={fullAccess} 
            onChange={(event, newValue) => setFullAccess(newValue)}
            sx={{ }}
          >
            <Tab disabled={creatingParticipant} label="Full Access" value={true} />
            <Tab disabled={creatingParticipant} label="Limited Access" value={false} />
          </Tabs>
        </Box>
        <Box
          display="flex" 
          flexDirection="row" 
          alignItems="center" 
          justifyContent="space-between"
          sx={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            height: "48px",
            padding: "0px 0px 0px 12px",
            width: "100%",
            margin: "24px 0px 0px 0px"
          }}
        >
          <InputBase
            placeholder="Enter email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ width: "100%" }}
            disabled={creatingParticipant}
          />
        </Box>
        {errorMessage &&
          <Box sx={{ color: "red", margin: "16px 0px" }}>
            {errorMessage}
          </Box>
        }
      </DialogContent>
      <DialogActions sx={{ margin: "16px" }}>
        <Button disabled={creatingParticipant} onClick={closeDialog}>Cancel</Button>
        <LoadingButton loading={creatingParticipant} disabled={(email ?? "") === ""} onClick={createParticipant} variant="contained">Invite</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddParticipantDialog