import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics"
import { Location } from "react-router-dom"
import softwareVersion from "../models/app"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

initializeApp(firebaseConfig)
const analytics = getAnalytics()

export const logPageView = (location: Location) => {
  logEvent(analytics, "page_view", {
    page_path: location.pathname,
    name: location.pathname,
    app_version: softwareVersion
  })
}
export const associateUser = (userId: string, email: string) => {
  setUserId(analytics, userId)
  setUserProperties(analytics, {
    user_id: userId,
    email: email
  })
}

export const logExport = (exportType: string, sensorCount: number) => {
  logEvent(analytics, "report_exported", {
    report: exportType,
    sensorCount: sensorCount,
    app_version: softwareVersion
  })
}

export const logSignUp = (email: string) => {
  logEvent(analytics, "sign_up", {
    email: email,
    app_version: softwareVersion
  })
}

export const logInviteSent = (projectId: number, access: string) => {
  logEvent(analytics, "invite_sent", {
    project: projectId,
    access: access,
    app_version: softwareVersion
  })
}

export const logProjectArchived = (projectId: number) => {
  logEvent(analytics, "project_archived", {
    project: projectId,
    app_version: softwareVersion
  })
}

export const logProjectUnarchived = (projectId: number) => {
  logEvent(analytics, "project_unarchived", {
    project: projectId,
    app_version: softwareVersion
  })
}

export const logInviteAccepted = (projectId: number, access: string) => {
  logEvent(analytics, "invite_accepted", {
    project: projectId,
    access: access,
    app_version: softwareVersion
  })
}

export const logAddedProject = () => {
  logEvent(analytics, "added_project", {
    app_version: softwareVersion
  })
}