import React, { useState } from "react"
import { Invitation } from "../../models/invitation"
import { Box, Button, CircularProgress, Divider, Typography } from "@mui/material"
import { acceptInvitation, declineInvitation } from "../../network/network"
import { useAppSelector } from "../../store/hooks"
import { logInviteAccepted } from "../../utilities/analytics"

interface Props {
  invitations: Invitation[]
  didRespondToInvitation: (invitation: Invitation) => void
}

const InvitationListView = (props: Props) => {
  const accessToken = useAppSelector(state => state.user.token)

  const [projectIdForNetworkRequest, setProjectIdForNetworkRequest] = useState<number | null>(null)

  const sendAcceptInvitationRequest = async (projectId: number) => {
    setProjectIdForNetworkRequest(projectId)
    if (!accessToken) {
      return
    }
    await acceptInvitation(accessToken, projectId)
    setProjectIdForNetworkRequest(null)
    const acceptedInvitation = props.invitations.find((invitation: Invitation) => {
      return invitation.projectId === projectId
    })
    if (acceptedInvitation) {
      props.didRespondToInvitation(acceptedInvitation)
      logInviteAccepted(projectId, acceptedInvitation.permission === "readwrite" ? "full" : "limited")
    }
  }

  const sendDeclineInvitationRequest = async (projectId: number) => {
    setProjectIdForNetworkRequest(projectId)
    if (!accessToken) {
      return
    }
    await declineInvitation(accessToken, projectId)
    setProjectIdForNetworkRequest(null)
    const acceptedInvitation = props.invitations.find((invitation: Invitation) => {
      return invitation.projectId === projectId
    })
    if (acceptedInvitation) {
      props.didRespondToInvitation(acceptedInvitation)
    }
  }

  return (
    <Box sx={{
      width: "100%",
      margin: 0,
      padding: "4px 12px",
    }}>
      <Typography variant="subtitle2" sx={{
        margin: "12px"
      }}>
        Invitations
      </Typography>
      <Box sx={{
        margin: 0,
        overflow: "auto",
        width: "320px"
      }}>
        {props.invitations.map((invitation: Invitation) => {
          return (
            <>
              <Box key={invitation.id} sx={{
                padding: "18px 0px"
              }}>
                <Box 
                  display="flex" 
                  flexDirection="column" 
                  gap="6px" 
                  justifyContent="space-between" 
                  alignItems="center"
                >
                  <Typography variant="body1">{invitation.projectName}</Typography>
                  {projectIdForNetworkRequest !== invitation.projectId &&
                    <Box display="flex" flexDirection="row" flexWrap="wrap" gap="12px">
                      <Button  
                        variant="outlined" 
                        color="primary"
                        onClick={() => { 
                          sendDeclineInvitationRequest(invitation.projectId)
                        }}
                      >
                        Decline
                      </Button>
                      <Button variant="contained" color="primary" onClick={() => {
                        sendAcceptInvitationRequest(invitation.projectId)
                      }}>Accept</Button>
                    </Box>
                  }
                  {projectIdForNetworkRequest === invitation.projectId &&
                    <CircularProgress />
                  }
                </Box>
              </Box>
              <Divider />
            </>
          )
        })}
      </Box>
    </Box>
  )
}

export default InvitationListView
