import { Moment } from "moment"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const elapsedTimeTooltip = (tooltipItems: any, sampleTime: Moment, placementDate: Moment) => {
  const elapsedTime = sampleTime.diff(placementDate, "seconds")
  const days = Math.floor(elapsedTime / 86400)
  const hours = Math.floor((elapsedTime - (days * 86400)) / 3600)
  const minutes = Math.floor((elapsedTime - (days * 86400) - (hours * 3600)) / 60)
  const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes
  const paddedHours = hours < 10 ? `0${hours}` : hours
  let dayString = ""
  if (days > 0) {
    dayString = `${days}.`
  }
  return `Elapsed Time: ${dayString}${paddedHours}:${paddedMinutes}`
}