import { Box, Button } from "@mui/material"
import StyledInput from "./StyledInput"
import React, { useState } from "react"
import { useAuth } from "./AuthProvider"

interface Props {
    buttonTitle: string,
    error: string | null,
    hidePasswordField?: boolean,
    onSubmit: (email: string, password: string) => void,
    children: React.ReactNode
}

export default function CredentialsForm(props: Props) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const { isLoading } = useAuth()

  return (
    <Box>
      <form onSubmit={(event) => {
        event.preventDefault()
        
        if (email.length == 0 || password.length == 0) {
          return
        }
        props.onSubmit(email, password)
      }}> 
        <Box
          flexDirection="column"
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            padding: "40px",
            borderRadius: "10px",
            border: "1px solid lightgray",
          }}>
          <Box>
            { props.error && <Box sx={{ color: "red", marginBottom: "20px" }}>{props.error}</Box> }
            <Box sx={{ marginBottom: "30px" }}>
              <StyledInput title="Email" type="username" value={email} onChange={setEmail}/>
            </Box>
            { !props.hidePasswordField && 
              <StyledInput title="Password" type="password" value={password} onChange={setPassword}/>
            }
          </Box>
          {props.children}
        </Box>
        <Button type="submit" variant="contained" color="primary" sx={{
          textTransform: "none",
          borderRadius: "30px",
          width: "calc(100% - 60px)",
          fontWeight: "bold",
          fontSize: "initial",
          margin: "30px"
        }} disabled={email.length == 0 || (password.length == 0 && !props.hidePasswordField) || isLoading} onClick={() => { 
          props.onSubmit(email, password)
        }}>
          {props.buttonTitle}
        </Button>
      </form>
    </Box>
  )
}