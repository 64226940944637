import React, { useRef } from "react"
import Sensor, { displayNameForSensor } from "../../models/sensor"
import "chartjs-adapter-moment"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js"
import "chartjs-adapter-moment"
import { Line } from "react-chartjs-2"
import { Box } from "@mui/material"
import annotationPlugin from "chartjs-plugin-annotation"
import SampleUnit from "../../models/units"
import { convertedTemperatureValue } from "../../utilities/calculations"
import { highestSample, lowestSample, samplesAfterDate, samplesAfterPlacementDate} from "../../utilities/samples"
import { elapsedTimeTooltip } from "./tooltips"
import moment from "moment"
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types"

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
)

interface Props {
    sensors: Sensor[],
    startDate?: moment.Moment
    endDate?: moment.Moment
    temperatureUnit: SampleUnit
    timezone: string
    colorMap: Map<number, string>
    min?: number
    max?: number
    forReport: boolean
}

const TemperatureChart = (props: Props) => {

  const chartReference = useRef<ChartJSOrUndefined<"line", ({ x: number; y: number; } | { x: Date; y: string; })[], string>>(null)

  const minSample = lowestSample(props.sensors)?.temperature ?? 0
  const maxSample = highestSample(props.sensors)?.temperature ?? 0

  let convertedMin = parseFloat(convertedTemperatureValue(minSample, props.temperatureUnit))
  let convertedMax = parseFloat(convertedTemperatureValue(maxSample, props.temperatureUnit))

  if (props.min !== undefined) {
    convertedMin = Math.min(convertedMin, parseFloat(convertedTemperatureValue(props.min, props.temperatureUnit)))
  }

  if (props.max !== undefined) {
    convertedMax = Math.max(convertedMax, parseFloat(convertedTemperatureValue(props.max, props.temperatureUnit)))
  }

  const range = convertedMax - convertedMin
  const padding = range * 0.05

  const options = {
    animation: {
      duration: 0
    },
    responsive: true,
    spansGaps: false,
    maintainAspectRatio: false,
    pointRadius: props.forReport ? 0 : 1,
    plugins: {
      tooltip: {
        callbacks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          title: (context: any) => {
            return moment(context[0].parsed.x).tz(props.timezone).format("MM/DD/YY H:mm")
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          label: (context: any) => {
            return `${context.parsed.y}º${props.temperatureUnit.toUpperCase()}`
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          footer: (tooltipItems: any) => {
            const sensorName = tooltipItems[0].dataset.label
            const sensor = props.sensors.find(sensor => displayNameForSensor(sensor) === sensorName)
            if (!sensor) {
              return ""
            }

            return elapsedTimeTooltip(tooltipItems, moment(tooltipItems[0].raw.x), moment(new Date(sensor.placementDate).setSeconds(0, 0)))
          }
        }
      },
      legend: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick: (e: any) => e.stopPropagation(),
        display: true
      },
      title: {
        display: true,
        text: "Temperature"
      },
      annotation: {
        annotations: {
          minLine: {},
          maxLine: {}
        }
      }
    },
    scales: {
      x: {
        offset: true,
        type: "time" as const,
        min: props.startDate?.toLocaleString(),
        max: props.endDate?.toLocaleString(),
        time: {
          timezone: props.timezone,
          displayFormats: {
            millisecond: "MM/DD/YY H:mm",
            second: "MM/DD/YY H:mm",
            minute: "MM/DD/YY H:mm",
            hour: "MM/DD/YY H:mm",
            day: "MM/DD/YY H:mm",
            week: "MM/DD/YY H:mm",
            month: "MM/DD/YY H:mm",
            quarter: "MM/DD/YY H:mm",
            year: "MM/DD/YY H:mm"
          }
        },
        gridLines: {
          display: true
        },
        ticks: {
          maxTicksLimit: 6
        }
      },
      y: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        afterFit: (scale: any) => {
          scale.width = 60
        },
        type: "linear" as const,
        suggestedMin: convertedMin - padding,
        suggestedMax: convertedMax + padding,
        ticks: {
          callback: function(label: string | number | undefined) {
            return Number(label).toFixed(1) + "º" + props.temperatureUnit.toUpperCase()
          }
        }
      }
    }
  }

  // @ts-expect-error - this is a hack to get around the fact that the annotation plugin doesn't support removing annotations
  delete options.plugins.annotation.annotations["maxLine"]

  // @ts-expect-error - this is a hack to get around the fact that the annotation plugin doesn't support removing annotations
  delete options.plugins.annotation.annotations["minLine"]

  if (props.min !== undefined) {
    options.plugins.annotation.annotations["minLine"] = {
      type: "line" as const,
      mode: "horizontal" as const,
      yMin: convertedTemperatureValue(props.min, props.temperatureUnit),
      yMax: convertedTemperatureValue(props.min, props.temperatureUnit),
      borderColor: "#00009B",
      borderWidth: 2,
      borderDash: [6, 6],
      label: {
        color: "#00009B",
        content: `Min ${convertedTemperatureValue(props.min, props.temperatureUnit)}`,
        display: true,
        backgroundColor: "#fff0",
        position: "start" as const,
        yAdjust: -10
      }
    }
  }

  if (props.max !== undefined) {
    options.plugins.annotation.annotations["maxLine"] = {
      type: "line" as const,
      mode: "horizontal" as const,
      yMin: convertedTemperatureValue(props.max, props.temperatureUnit),
      yMax: convertedTemperatureValue(props.max, props.temperatureUnit),
      borderColor: "#8D1806",
      borderWidth: 2,
      borderDash: [6, 6],
      label: {
        color: "#8D1806",
        content: `Max ${convertedTemperatureValue(props.max, props.temperatureUnit)}`,
        display: true,
        backgroundColor: "#fff0",
        position: "start" as const,
        yAdjust: -10
      }
    }
  }

  const dataForSensor = (sensor: Sensor) => {
    let viableSamples = props.startDate ? samplesAfterDate(sensor, props.startDate) : samplesAfterPlacementDate(sensor)
    const endDate = props.endDate
    if (endDate !== undefined) {
      viableSamples = viableSamples.filter(sample => sample.time.valueOf() <= endDate.valueOf())
    }
    if (!viableSamples || viableSamples.length === 0) {
      return [{
        x: Number.NaN,
        y: Number.NaN
      }]
    }

    const result = []
    const firstSample = viableSamples[0]
    const lastSample = viableSamples[viableSamples.length - 1]
    const sampleInterval = sensor.sampleInterval
    let currentSampleIndex = 0
    let expectedSampleTime = firstSample.time
    while (expectedSampleTime <= lastSample.time && currentSampleIndex < viableSamples.length) {
      const currentSample = viableSamples[currentSampleIndex]
      if (currentSample.time.valueOf() !== expectedSampleTime.valueOf()) {
        result.push({
          x: Number.NaN,
          y: Number.NaN
        })
        expectedSampleTime = moment(expectedSampleTime.valueOf() + sampleInterval * 60 * 1000)
        continue
      } else {
        result.push({
          x: currentSample.time.toDate(),
          y: convertedTemperatureValue(currentSample.temperature, props.temperatureUnit)
        })
        currentSampleIndex += 1
        expectedSampleTime = moment(expectedSampleTime.valueOf() + sampleInterval * 60 * 1000)
      }
    }
    return result
  }
    
  const data = {
    datasets: props.sensors.map(sensor => {
      return {
        label: displayNameForSensor(sensor),
        borderColor: props.forReport ? props.colorMap.get(sensor.id) : `${props.colorMap.get(sensor.id)}`,
        backgroundColor: props.colorMap.get(sensor.id),
        data: dataForSensor(sensor),
        borderWidth: 1
      }
    })
  }

  if (props.sensors.length === 0) {
    return (
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-around" sx={{
        width: "100%",
        height: "100%"
      }}>
        Select one or more sensors to view their temperature data.
      </Box>
    )
  }

  return (
    <Line ref={chartReference} data={data} options={options} width="100%" height="100%" />
  )
}

export default TemperatureChart
