import axios from "axios"
import Project from "../models/project"
import Sample from "../models/sample"
import Participant from "../models/participant"
import { Invitation, Permission } from "../models/invitation"
import { Alert } from "../models/alert"
import { AlertRecipient } from "../models/alertrecipient"
import { AlertTrigger } from "../models/alerttrigger"
import moment from "moment"

export const fetchProjects = async (token: string) : Promise<Project[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data as Project[]
}

type ServerSample = {
  id: number
  temperature: number
  time: Date
  updatedAt: Date
}

export const fetchSensorData = async (token: string, projectId: number, placementId: number, sensorId: number) : Promise<Sample[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PLACEMENT_API}/api/v2/projects/${projectId}/placements/${placementId}/sensors/${sensorId}/samples`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })

  return results.data.map((serverSample: ServerSample) : Sample => {
    return {
      id: serverSample.id,
      temperature: serverSample.temperature,
      time: moment(serverSample.time),
      updatedAt: moment(serverSample.updatedAt)
    }
  }).sort((a: Sample, b: Sample) => { a.time.valueOf() - b.time.valueOf() })
}

export const fetchInvitations = async (token: string) : Promise<Invitation[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_INVITATIONS_API}/api/v2/invitations`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const acceptInvitation = async (token: string, projectId: number) : Promise<boolean> => {
  const results = await axios.put(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/invitations/accept`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const declineInvitation = async (token: string, projectId: number) : Promise<boolean> => {
  const results = await axios.put(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/invitations/decline`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const fetchAlerts = async (token: string, projectId: number, placementId: number) : Promise<Alert[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_ALERTS_API}/api/v2/projects/${projectId}/placements/${placementId}/alerts`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const fetchAlertRecipients = async (token: string, projectId: number, placementId: number) : Promise<AlertRecipient[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_ALERTS_API}/api/v2/projects/${projectId}/placements/${placementId}/recipients`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const fetchAlertTriggers = async (token: string, projectId: number, placementId: number) : Promise<AlertTrigger[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_ALERTS_API}/api/v2/projects/${projectId}/placements/${placementId}/alert-triggers`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const fetchInvitationsForProject = async (token: string, projectId: number) : Promise<Invitation[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/invitations`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const fetchProjectParticipantList = async (token: string, projectId: number) : Promise<Participant[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/permissions`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const createNewProject = async (token: string, name: string) : Promise<Project> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects`, {
    name: name,
    description: "",
    isArchived: false
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const updateProject = async (token: string, projectId: number, name: string) : Promise<Project> => {
  const results = await axios.put(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}`, {
    name: name,
    description: "",
    isArchived: false
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const archiveProject = async (token: string, projectId: number) : Promise<boolean> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/archive`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const unarchiveProject = async (token: string, projectId: number) : Promise<boolean> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/unarchive`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const createInvitation = async (token: string, projectId: number, email: string, permission: Permission) : Promise<boolean> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/invitations`, {
    projectId: projectId,
    email: email,
    permission: permission
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const deleteInvitation = async (token: string, projectId: number, email: string) : Promise<boolean> => {
  const results = await axios.delete(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/invitations?email=${encodeURIComponent(email)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const updatePermission = async (token: string, projectId: number, userId: string, permission: Permission) : Promise<boolean> => {
  const results = await axios.put(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/permissions`, {
    projectId: projectId,
    userId: userId,
    permission: permission
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const deletePermission = async (token: string, projectId: number, userId: string) : Promise<boolean> => {
  const results = await axios.delete(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/permissions?userId=${encodeURIComponent(userId)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}