import { CurveFitType, UltimateStrengthUnit } from "../models/curvefit"
import Sensor from "../models/sensor"
import SampleUnit from "../models/units"
import differenceChartData, { DifferenceEntry } from "../ui/charts/temperatureDifferenceCalculator"
import { maximumSampleDate } from "./samples"
import { minimumSampleDate } from "./samples"

export const convertedTemperatureValue = (celciusTemperature: number, unit: SampleUnit) => {
  if (unit === "c") {
    return celciusTemperature.toFixed(1)
  } else {
    return (Math.round((celciusTemperature * (9/5) + 32) * 10) / 10).toFixed(1)
  }
}

export const convertedDifferenceValue = (celciusDifference: number, unit: SampleUnit) => {
  if (unit === "c") {
    return celciusDifference.toFixed(1)
  } else {
    return (Math.round((celciusDifference * (9/5)) * 10) / 10).toFixed(1)
  }
}

export const convertInitialMaturityValue = (initialMaturityCelciusHours: number, unit: SampleUnit) => {
  if (unit === "c") {
    return initialMaturityCelciusHours
  } else {
    return initialMaturityCelciusHours * (9/5)
  }
}

export const convertKValue = (kValue: number, unit: SampleUnit) => {
  if (unit === "c") {
    return kValue
  } else {
    return kValue * (5/9)
  }
}

const megapascalToPSICoefficient = 145.03773773

export const convertUltimateStrengthValue = (ultimateStrength: number, unit: UltimateStrengthUnit) => {
  if (unit === "mpa") {
    return ultimateStrength
  } else {
    return ultimateStrength * megapascalToPSICoefficient
  }
}

export const convertCurveFitIntercept = (intercept: number, unit: SampleUnit, strengthUnit: UltimateStrengthUnit, curveFitType: CurveFitType, slope: number) => {
  if (curveFitType === "disabled" || curveFitType === "hyperbolic") {
    throw new Error("Cannot get intercept with disabled or hyperbolic curve type")
  }
  if (unit === "c") {
    if (strengthUnit === "mpa") {
      return intercept
    } else {
      return intercept * megapascalToPSICoefficient
    }
  } else {
    if (strengthUnit === "mpa") {
      if (curveFitType === "log") {
        return intercept - Math.log10(9.0 / 5.0) * slope
      } else {
        return intercept - Math.log(9.0 / 5.0) * slope
      }
    } else {
      if (curveFitType === "log") {
        return (intercept - (Math.log10(9.0 / 5.0) * slope)) * megapascalToPSICoefficient
      } else {
        return (intercept - (Math.log(9.0 / 5.0) * slope)) * megapascalToPSICoefficient
      }
    }
  }
}

export const convertSlope = (slope: number, strengthUnit: UltimateStrengthUnit) => {
  if (strengthUnit === "mpa") {
    return slope
  } else {
    return slope * megapascalToPSICoefficient
  }
}

export type MaxDifferenceCalculation = {
  maxDifference: number,
  time: Date
  minSensor: Sensor,
  maxSensor: Sensor
}

export const calculateMaxDifference = (sensors: Sensor[]) : MaxDifferenceCalculation | null => {
  const minimumDate = minimumSampleDate(sensors)
  const maxDate = maximumSampleDate(sensors)

  if (!minimumDate || !maxDate) {
    return null
  }
  const differencePoints: Map<number, DifferenceEntry> = differenceChartData(sensors, minimumDate, maxDate)
  
  let maxDifference: number | null = null
  let maxDifferenceDate: Date | null = null

  differencePoints.forEach((point: DifferenceEntry, key: number) => {
    const difference = point.max - point.min
    if (maxDifference === null || difference > maxDifference) {
      maxDifference = difference
      maxDifferenceDate = new Date(key)
    }
  })

  if (maxDifferenceDate === null || maxDifference === null) {
    return null
  }

  let minSensor: Sensor | null = null
  let maxSensor: Sensor | null = null

  let minValue: number | null = null
  let maxValue: number | null = null

  // get the sensor's sample at the max difference date
  sensors.forEach((sensor: Sensor) => {
    const sample = sensor.samples?.find((sample) => {
      return sample.time.valueOf() === maxDifferenceDate?.valueOf()
    })
    if (sample) { 
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (minSensor === null || sample.temperature < minValue!) {
        minSensor = sensor
        minValue = sample.temperature
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (maxSensor === null || sample.temperature > maxValue!) {
        maxSensor = sensor
        maxValue = sample.temperature
      }
    }
  })

  if (!minSensor || !maxSensor || !minSensor || !maxSensor || !maxDifference) {
    return null
  }

  return {
    maxDifference: maxDifference,
    time: maxDifferenceDate,
    minSensor: minSensor,
    maxSensor: maxSensor
  }
}
