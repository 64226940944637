import React from "react"
import Sensor from "../../models/sensor"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from "@mui/material"
import moment from "moment"

interface Props {
  id: string
  sensors: Sensor[]
}

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#AAAAAA",
  },
}))

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#7F7F7F"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 6
  },
  "&:not(:last-child)": {
    borderRight: "1px solid black"
  }
}))

const SelectedSensorsSummaryTableView = (props: Props) => {

  function createData(name: string, serial: string, location: string, depth: string, placementDate: string, endDate: string, interval: number) {
    return { name, serial, location, depth, placementDate, endDate, interval }
  }

  const rows = props.sensors.map(sensor => {
    return createData(
      sensor.name && sensor.name.length > 0 ? sensor.name : "-",
      sensor.serial,
      sensor.location ?? "-",
      sensor.depth ? `${sensor.depth} ${sensor.depthUnit}` : "-",
      moment(sensor.placementDate).format("YYYY/MM/DD HH:mm"),
      "-",
      sensor.sampleInterval
    )
  })

  return (
    <TableContainer id={props.id} component={Paper} sx={{
      width: "100%"
    }}>
      <Table sx={{
        border: "2px solid black"
      }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              Name
            </StyledTableCell>
            <StyledTableCell>
              Serial Number
            </StyledTableCell>
            <StyledTableCell>
              Location
            </StyledTableCell>
            <StyledTableCell>
              Depth
            </StyledTableCell>
            <StyledTableCell>
              Placement Date
            </StyledTableCell>
            <StyledTableCell>
              End Date
            </StyledTableCell>
            <StyledTableCell>
              Interval (mins)
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.serial}>
              <StyledTableCell align="right">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.serial}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.location}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.depth}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.placementDate}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.endDate}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.interval}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SelectedSensorsSummaryTableView
