import { Box, Divider, Link, List, ListItem, Typography } from "@mui/material"
import { Mail, LocalPhone, QuestionMark } from "@mui/icons-material"
import React from "react"
import { useAuth } from "../auth/AuthProvider"
import softwareVersion from "../../models/app"

interface Props {
  onSignOut: () => void
}

const ProfileView = (props: Props) => {
  const { user } = useAuth()
  return (
    <Box display="flex" flexDirection="column" sx={{
      padding: "24px",
      maxWidth: "400px"
    }}>
      <Typography variant="h5">
        Account
      </Typography>
      <Typography variant="caption" sx={{
        marginTop: "12px"
      }}>
        {user?.email}
      </Typography>
      <List sx={{
        padding: "0px 12px",
        margin: "0px"
      }}>
        <ListItem color="primary" onClick={props.onSignOut} sx={{
          cursor: "pointer",
          padding: "0px",
          margin: "12px 0px"
        }}>
          <Typography color="primary">
            Sign Out
          </Typography>
        </ListItem>
      </List>
      <Typography variant="caption" sx={{
        marginTop: "24px"
      }}>
        Support
      </Typography>
      <List sx={{
        padding: "0px 12px",
        margin: "0px"
      }}>
        <Link href="mailto:support@commandcenterconcrete.com" sx={{
          textDecoration: "none",
          color: "black"
        }}>
          <ListItem color="primary" sx={{
            cursor: "pointer",
            padding: "0px",
            marginTop: "12px"
          }}>
            <Mail />
            <Typography sx={{
              marginLeft: "8px"
            }}>
              Email us
            </Typography>
          </ListItem>
        </Link>
        <Divider sx={{
          margin: "12px 0px"
        }} />
        <Link href="tel:8884516233" sx={{
          textDecoration: "none",
          color: "black"
        }}>
          <ListItem color="primary" sx={{
            padding: "0px",
            justifyContent: "space-between"
          }}>
            <Box display="flex" flexDirection="row">
              <LocalPhone />
              <Typography sx={{
                marginLeft: "8px"
              }}>
                Call us
              </Typography>
            </Box>
            <Typography color="gray">
              (888) 451-6233
            </Typography>
          </ListItem>
        </Link>
        <Divider sx={{
          margin: "12px 0px"
        }} />
        <Link target="_blank" href="https://www.commandcenterconcrete.com/faq/" sx={{
          textDecoration: "none",
          color: "black"
        }}>
          <ListItem color="primary" sx={{
            padding: "0px"
          }}>
            <QuestionMark />
            <Typography sx={{
              marginLeft: "8px"
            }}>
              Frequently asked questions
            </Typography>
          </ListItem>
        </Link>
        <Box display="flex" flexDirection="column" alignItems="center" sx={{
          marginTop: "24px"
        }}>
          <Typography variant="body1">
            COMMAND Center
          </Typography>
          <Typography variant="body2" color="gray">
            Version {softwareVersion}
          </Typography>
        </Box>
      </List>
    </Box>
  )
}

export default ProfileView
