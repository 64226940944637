import React, { useEffect, useState } from "react"
import { useAppSelector } from "../../../../store/hooks"
import { fetchAlertRecipients } from "../../../../network/network"
import { AlertRecipient } from "../../../../models/alertrecipient"
import { Box, CircularProgress, Divider, List, ListItem, Typography } from "@mui/material"

interface Props {
  projectId: number
  placementId: number
}

const AlertRecipientsView = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const accessToken = useAppSelector(state => state.user.token)
  const [recipients, setRecipients] = useState<AlertRecipient[]>([])

  useEffect(() => {
    const loadAlertsFromNetwork = async () => {
      if (!accessToken) {
        return
      }
      setLoading(true)
      const recipientsResults = await fetchAlertRecipients(accessToken, props.projectId, props.placementId)
      setRecipients(recipientsResults)
      setLoading(false)
    }
    loadAlertsFromNetwork()
  }, [])

  if (loading) {
    return (
      <Box 
        display="flex" 
        alignItems="center" 
        flexDirection="column"
        sx={{ width: "400px", padding: "12px" }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <List sx={{
      width: "100%",
    }}>
      {recipients.length === 0 && 
        <Typography variant="body1" color="gray" sx={{
          textAlign: "center",
          padding: "24px 0px"
        }}>
          No recepients configured to receive alerts.
        </Typography>
      }
      {recipients.map(recipient => {
        return (  
          <ListItem key={recipient.id} sx={{
            width: "100%"
          }}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
              width: "100%",
              padding: 0,
              margin: 0
            }}>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography variant="body1" sx={{
                  marginBottom: "6px"
                }}>
                  {recipient.name}
                </Typography>
                <Typography variant="caption">
                  {recipient.contact}
                </Typography>
              </Box>
              <Typography variant="body1" color="gray">
                {recipient.type.toLocaleUpperCase()}
              </Typography>
            </Box>
            <Divider />
          </ListItem>
        )
      })}
    </List>
  )
}

export default AlertRecipientsView
