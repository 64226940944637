import Sensor from "../../models/sensor"
import { samplesAfterDate } from "../../utilities/samples"

export type DifferenceEntry = {
  min: number,
  max: number,
  sensorCount: number
}

const differenceChartData = (sensors: Sensor[], startDate: moment.Moment, endDate: moment.Moment) : Map<number, DifferenceEntry> => {
  const resultMap = new Map<number, DifferenceEntry>()
  sensors.forEach(sensor => {
    const samples = samplesAfterDate(sensor, startDate)
    samples.forEach(sample => {
      if (sample.time.valueOf() < startDate.valueOf() || sample.time.valueOf() > endDate.valueOf()) {
        return
      }
      const differenceEntry = resultMap.get(sample.time.valueOf())
      if (!differenceEntry) {
        resultMap.set(sample.time.valueOf(), {
          min: sample.temperature,
          max: sample.temperature,
          sensorCount: 1
        })
      } else {
        if (sample.temperature < differenceEntry.min) {
          differenceEntry.min = sample.temperature
        }
        if (sample.temperature > differenceEntry.max) {
          differenceEntry.max = sample.temperature
        }
        differenceEntry.sensorCount = differenceEntry.sensorCount + 1
        resultMap.set(sample.time.valueOf(), differenceEntry)
      }
    })
  })
  return resultMap
}

export default differenceChartData
