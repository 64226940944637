import { Box, ListItem, Typography } from "@mui/material"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import React from "react"

interface Props {
  title: string
  value?: string
  showDisclosure?: boolean
  onClick?: () => void
}

const ValueCell = (props: Props) => {
  return (
    <ListItem sx={{
      m: 0,
      p: "12px 0px",
      cursor: props.onClick ? "pointer" : "default"
    }} onClick={props.onClick}>
      <Box 
        display="flex" 
        justifyContent="space-between" 
        flexDirection="row"
        gap={4}
        sx={{
          width: "100%"
        }}
      >
        <Typography>
          {props.title}
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography sx={{
            color: "gray"
          }}>
            {props.value}
          </Typography>
          {props.showDisclosure &&
            <ChevronRightIcon sx={{
              color: "gray"
            }} />
          }
        </Box>
      </Box>
    </ListItem>
  )
}

export default ValueCell
