import { Divider, List, Typography } from "@mui/material"
import React from "react"
import MaturityMethod, { ArrheniusParameters, NurseSaulParameters } from "../../../models/maturitymethod"
import { convertedTemperatureValue } from "../../../utilities/calculations"
import ValueCell from "../../reusable/ValueCell"

interface Props {
  maturityMethod: MaturityMethod
}

const MaturityMethodView = (props: Props) => {
  let maturityMethodString = "Disabled"
  if (props.maturityMethod.type === "nurse saul") {
    maturityMethodString = "Nurse-Saul"
  } else if (props.maturityMethod.type === "arrhenius") {
    maturityMethodString = "Arrhenius"
  }
  return (
    <>
      <Typography variant="caption">
        Maturity Method
      </Typography>
      <List sx={{
        padding: "0px 12px",
        marginTop: "12px"
      }}>
        <ValueCell 
          title="Maturity Method" 
          value={maturityMethodString} 
        />
        {props.maturityMethod.type === "nurse saul" &&
          <>
            <Divider />
            <ValueCell 
              title="Unit" 
              value={`º${props.maturityMethod.temperatureUnit.toUpperCase()}-hr`} 
            />
            <Divider />
            <ValueCell 
              title={`Datum Temp (º${props.maturityMethod.temperatureUnit.toUpperCase()})`}
              value={parseFloat(convertedTemperatureValue((props.maturityMethod.parameters as NurseSaulParameters).datumTemperature, props.maturityMethod.temperatureUnit)).toFixed(0)} 
            />
          </>
        }
        {props.maturityMethod.type === "arrhenius" &&
          <>
            <Divider />
            <ValueCell 
              title="Activation Energy (J/mol)"
              value={`${(props.maturityMethod.parameters as ArrheniusParameters).activationEnergy}`}
            />
            <Divider />
            <ValueCell 
              title="Reference Temp Unit"
              value={`º${props.maturityMethod.temperatureUnit.toUpperCase()}`}
            />
            <Divider />
            <ValueCell 
              title={`Reference Temperature (º${props.maturityMethod.temperatureUnit.toUpperCase()})`}
              value={convertedTemperatureValue((props.maturityMethod.parameters as ArrheniusParameters).referenceTemperature, props.maturityMethod.temperatureUnit)}
            />
          </>
        }
      </List>
    </>
  )
}

export default MaturityMethodView
