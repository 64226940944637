import { Divider, Menu, MenuItem, Typography } from "@mui/material"
import React from "react"

interface Props {
  open: boolean
  menuElement: null | HTMLElement
  onClose: () => void
  onTemperatureDifferentialReportSelected: () => void
  onExportToExcelSelected: () => void
  selectedSensorsCount: number,
  totalSensorCount: number
}

const ReportMenu = (props: Props) => {
  return (
    <Menu
      id="report-menu"
      anchorEl={props.menuElement}
      open={props.open}
      onClose={() => {
        props.onClose()
      }}
    >
      <Typography variant="caption" color="black" sx={{
        margin: "14px"
      }}>
        PDF Reports
      </Typography>
      <MenuItem disabled={props.selectedSensorsCount < 2} onClick={() => {
        props.onClose()
        props.onTemperatureDifferentialReportSelected()
      }}>
        <Typography variant="body1">
          Temperature Differential
        </Typography>
      </MenuItem>
      <Divider />
      <Typography variant="caption" color="black" sx={{
        margin: "14px"
      }}>
        Export...
      </Typography>
      <MenuItem disabled={props.totalSensorCount == 0} onClick={() => {
        props.onClose()
        props.onExportToExcelSelected()
      }}>
        <Typography variant="body1">
          To Excel
        </Typography>
      </MenuItem>
    </Menu>
  )
}

export default ReportMenu