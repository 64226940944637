import React from "react"
import Sensor from "../../models/sensor"
import { Box, Divider, List, Typography } from "@mui/material"
import ValueCell from "../reusable/ValueCell"
import moment from "moment"

interface Props {
  sensor: Sensor,
  timezone: string
}

const SensorDetailsView = (props: Props) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" sx={{
      maxWidth: "320px",
      padding: "12px"
    }}>
      <Typography variant="h5">
        Sensor Details
      </Typography>

      <List sx={{
        width: "100%"
      }}>
        <ValueCell
          title="Name"
          value={props.sensor.name ?? "Not Set"}
        />
        <Divider />
        <ValueCell
          title="Location"
          value={props.sensor.location ?? "Not Set"}
        />
        <Divider />
        <ValueCell
          title="Depth"
          value={props.sensor.depth ? `${props.sensor.depth} ${props.sensor.depthUnit}` : "Not Set"}
        />
        <Divider />
        <ValueCell
          title="Placement Date"
          value={`${moment(new Date(props.sensor.placementDate)).tz(props.timezone).format("MM/DD/YY HH:mm")}`}
        />
        <Divider />
        <ValueCell
          title="Alerts Enabled"
          value={props.sensor.enableNotifications ? "Yes" : "No"}
        />
        <Divider />
        <ValueCell
          title="Serial Number"
          value={props.sensor.serial}
        />
        <Divider />
        <ValueCell
          title="Sample Interval (min)"
          value={`${props.sensor.sampleInterval}`}
        />
      </List>
    </Box>
  )
}

export default SensorDetailsView
