import { Box, Divider, List, ListItem, Typography } from "@mui/material"
import React from "react"
import ValueCell from "../../reusable/ValueCell"

interface Props {
  name: string
  timeZone: string
  onAlertsClicked: () => void
}

const PlacementGeneralInfoView = (props: Props) => {
  return (
    <List sx={{
      padding: "0px 12px",
      marginTop: "24px"
    }}>
      <ListItem sx={{
        m: 0,
        p: "12px 0px"
      }}>
        <Box display="flex" justifyContent="space-between" flexDirection="row">
          <Typography>
            {props.name}
          </Typography>
        </Box>
      </ListItem>
      <Divider />
      <ValueCell title="Time Zone" value={props.timeZone} />
      <Divider />
      <ValueCell 
        onClick={props.onAlertsClicked} 
        title="Alerts" 
        showDisclosure={true}
      />
    </List>
  )
}

export default PlacementGeneralInfoView