import { Box, Button, List, ListItem, Tab, Tabs, Tooltip, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import Sensor from "../../models/sensor"
import SensorView from "./SensorView"
import SampleUnit from "../../models/units"
import ReportMenu from "../reusable/ReportMenu"
import Placement from "../../models/placement"
import { latestSample } from "../../utilities/samples"

interface Props {
  sensors: Sensor[]
  temperatureUnit: SampleUnit
  selectedSensors: number[]
  onSensorSelectionChange: (sensor: Sensor) => void
  colorMap: Map<number, string>
  timezone: string
  placement: Placement
  onTemperatureDifferentialReportSelected: () => void
  onExportToExcelSelected: () => void
  reportsDisabled: boolean
}

const SensorListView = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [sortedSensors, setSortedSensors] = useState<Sensor[]>(props.sensors)

  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null)
  const menuIsOpen = Boolean(menuElement)

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuElement(event.currentTarget)
  }

  const sorted = (sensors: Sensor[]) => {
    if (selectedTab === 0) {
      let newList = sensors.slice()
      newList = newList.sort((a: Sensor, b: Sensor) => {
        let aName = a.name
        if (!aName || aName.length === 0) {
          aName = a.serial
        }
        let bName = b.name
        if (!bName || bName.length === 0) {
          bName = b.serial
        }
        if (aName < bName) {
          return -1
        } else if (aName > bName) {
          return 1
        } else {
          return 0
        }
      })
      return newList
    } else {
      let newList = sensors.slice()
      newList = newList.sort((a: Sensor, b: Sensor) => {
        const aDate = latestSample(a)?.time ?? Date.parse(a.updatedAt)
        const bDate = latestSample(b)?.time ?? Date.parse(b.updatedAt)
        if (aDate > bDate) {
          return -1
        } else if (aDate < bDate) {
          return 1
        } else {
          return 0
        }
      })
      return newList
    }
  }

  useEffect(() => {
    setSortedSensors(sorted(props.sensors))
  }, [props.sensors])

  useEffect(() => {
    setSortedSensors(sorted(sortedSensors))
  }, [selectedTab])

  const reportButton = () => {
    return (
      <Button onClick={handleMenuClick} disabled={props.reportsDisabled}>
        <Typography variant="subtitle2" color={props.reportsDisabled ? "gray" : "primary"}>
          Report...
        </Typography>
      </Button>
    )
  }

  return (
    <Box flex={1} display="flex" flexDirection="column" sx={{
      overflow: "hidden",
      height: "100%"
    }}>
      <Box 
        display="flex" 
        alignItems="center" 
        justifyContent="space-between" 
        flexDirection="row" 
        sx={{
          marginTop: "24px",
        }}
      >
        <Typography variant="subtitle2" sx={{
          padding: "0",
          paddingLeft: "24px"
        }}>
          Sensors
        </Typography>
        {props.reportsDisabled &&
          <Tooltip title="You have limited access to this placement">
            <span>
              {reportButton()}
            </span>
          </Tooltip>
        }
        {!props.reportsDisabled &&
          reportButton()
        }
        
      </Box>
      <ReportMenu 
        open={menuIsOpen}
        menuElement={menuElement}
        onClose={() => {
          setMenuElement(null)
        }}
        onTemperatureDifferentialReportSelected={() => {
          props.onTemperatureDifferentialReportSelected()
        }}
        onExportToExcelSelected={props.onExportToExcelSelected}
        selectedSensorsCount={props.selectedSensors.length}
        totalSensorCount={props.sensors.length}
      />
      <Tabs 
        variant="fullWidth" 
        value={selectedTab} 
        onChange={(event, newValue) => setSelectedTab(newValue)}
        sx={{
          margin: "24px 24px 0px 24px"
        }}
      >
        <Tab label="Name" value={0} />
        <Tab label="Recent" value={1} />
      </Tabs>
      <List sx={{
        flex: 1,
        margin: 0,
        marginTop: "12px",
        overflow: "scroll",
      }}>
        {sortedSensors.map((sensor: Sensor) => {
          return (
            <ListItem key={sensor.id}>
              <SensorView
                sensor={sensor} 
                placement={props.placement}
                temperatureUnit={props.temperatureUnit}
                selected={props.selectedSensors.indexOf(sensor.id) !== -1} 
                onSelectionChange={() => {
                  props.onSensorSelectionChange(sensor)
                }}
                timezone={props.timezone}
                color={props.colorMap.get(sensor.id) ?? "#000"}
              />
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

export default SensorListView
