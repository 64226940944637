import { Divider, List, Typography } from "@mui/material"
import React from "react"
import TemperatureLimit from "../../../models/temperaturelimit"
import SampleUnit from "../../../models/units"
import { convertedDifferenceValue, convertedTemperatureValue } from "../../../utilities/calculations"
import ValueCell from "../../reusable/ValueCell"

interface Props {
  temperatureLimit: TemperatureLimit
  temperatureUnit: SampleUnit
}

const TemperatureLimitsView = (props: Props) => {  
  return (
    <>
      <Typography variant="caption">
        Temperature Limits
      </Typography>
      <List sx={{
        padding: "0px 12px",
        marginTop: "12px"
      }}>
        <ValueCell 
          title="Temp Unit" 
          value={`º${props.temperatureUnit.toUpperCase()}`} 
        />
        <Divider />
        <ValueCell 
          title="Minimum Temp" 
          value={ props.temperatureLimit.minimumTemperature ? convertedTemperatureValue(props.temperatureLimit.minimumTemperature, props.temperatureUnit) : "Not Set" } 
        />
        <Divider />
        <ValueCell 
          title="Maximum Temp" 
          value={ props.temperatureLimit.maximumTemperature ? convertedTemperatureValue(props.temperatureLimit.maximumTemperature, props.temperatureUnit) : "Not Set" } 
        />
        <Divider />
        <ValueCell 
          title="Temp Difference" 
          value={ props.temperatureLimit.difference ? convertedDifferenceValue(props.temperatureLimit.difference, props.temperatureUnit) : "Not Set" } 
        />
      </List>
    </>
  )
}

export default TemperatureLimitsView
