export const hyperbolicStrength = (maturity: number, initialMaturity: number, ultimateStrength: number, kValue: number): number => {
  if (maturity > initialMaturity) {
    return ultimateStrength * kValue * (maturity - initialMaturity) / 
      (1 + kValue * (maturity - initialMaturity))
  } else {
    return 0
  }
}

export const lnStrength = (maturity: number, slope: number, intercept: number): number => {
  if (maturity === 0 || slope === 0) {
    return 0
  } else {
    return Math.max((Math.log(maturity) * slope) + intercept, 0)
  }
}

export const logStrength = (maturity: number, slope: number, intercept: number): number => {
  if (maturity === 0 || slope === 0) {
    return 0
  } else {
    return Math.max((Math.log10(maturity) * slope) + intercept, 0)
  }
}