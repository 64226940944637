import React, { useEffect, useState } from "react"
import { useAppSelector } from "../../../../store/hooks"
import { fetchAlertTriggers } from "../../../../network/network"
import { Box, CircularProgress, Typography } from "@mui/material"
import { AlertTrigger } from "../../../../models/alerttrigger"
import { nameForAlertType } from "../../../../models/alert"
import moment from "moment"

interface Props {
  projectId: number
  placementId: number
}

const AlertLogsView = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const accessToken = useAppSelector(state => state.user.token)
  const [triggers, setTriggers] = useState<AlertTrigger[]>([])

  useEffect(() => {
    const fetchTriggersFromNetwork = async () => {
      if (!accessToken) {
        return
      }
      setLoading(true)
      const triggersResults = await fetchAlertTriggers(accessToken, props.projectId, props.placementId)
      setTriggers(triggersResults)
      setLoading(false)
    }
    fetchTriggersFromNetwork()
  }, [])

  if (loading) {
    return (
      <Box 
        display="flex" 
        alignItems="center" 
        flexDirection="column"
        sx={{ width: "400px", padding: "12px" }}
      >
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column" sx={{
      width: "100%",
      padding: "12px"
    }}>
      {triggers.length === 0 && 
        <Typography variant="body1" color="gray" sx={{
          textAlign: "center",
          padding: "24px 0px"
        }}>
          No alerts logs available.
        </Typography>
      }
      {triggers.map(trigger => {
        return (
          <Box key={trigger.id} display="flex" flexDirection="row" justifyContent="space-between" gap="12px" alignItems="flex-start" sx={{
            width: "100%",
            padding: "12px"
          }}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="caption">
                {nameForAlertType(trigger.type)}
              </Typography>
              <Typography variant="body1">
                { trigger.sensor == null ? `Deleted Sensor ${trigger.sensorSerial}` : `Sensor ${trigger.sensor.name ?? trigger.sensor.serial}` }
              </Typography>
            </Box>
            <Typography variant="body1" color="gray" sx={{
              textAlign: "right"
            }}>
              {`${moment(trigger.sampleTime).format("YYYY/MM/DD HH:mm")}`}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

export default AlertLogsView
