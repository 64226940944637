import { Button, IconButton, Toolbar, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import React from "react"

interface Props {
  title: string
  onClose: () => void
  onSave: () => void
}

const ReportToolbar = (props: Props) => {
  return (
    <Toolbar>
      <IconButton
        edge="end"
        color="inherit"
        onClick={props.onClose}
      >
        <CloseIcon />
      </IconButton>
      <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
        Preview
      </Typography>
      <Button color="primary" onClick={props.onSave}>
        Save Report
      </Button>
    </Toolbar>
  )
}

export default ReportToolbar
