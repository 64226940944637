import { Divider } from "@mui/material"
import React from "react"
import CurveFit, { HyperbolicParameters } from "../../../../models/curvefit"
import SampleUnit from "../../../../models/units"
import { convertInitialMaturityValue, convertKValue, convertUltimateStrengthValue } from "../../../../utilities/calculations"
import ValueCell from "../../../reusable/ValueCell"
import MaturityMethod from "../../../../models/maturitymethod"

interface Props {
  curveFit: CurveFit
  unit: SampleUnit
  maturityMethod: MaturityMethod
}

const HyperbolicDataView = (props: Props) => {
  let unit = ""
  if (props.curveFit.parameters.strengthUnit === "mpa") {
    unit = "MPa"
  } else {
    unit = "psi"
  }

  const hyperbolicParameters = props.curveFit.parameters as HyperbolicParameters

  return (
    <>
      <Divider />
      <ValueCell
        title="Strength Unit"
        value={unit}
      />
      <Divider />
      {props.maturityMethod.type === "arrhenius" &&
        <>
          <ValueCell
            title={"Initial Eq. Age (hr)"}
            value={`${hyperbolicParameters.initialEquivalentAge}`}
          />
          <Divider />
          <ValueCell
            title="k-value"
            value={`${hyperbolicParameters.kValue.toFixed(2)}`}
          />
          <Divider />
          {hyperbolicParameters.strengthUnit === "mpa" &&
            <ValueCell
              title={`Ultimate Strength (${unit})`}
              value={`${convertUltimateStrengthValue(hyperbolicParameters.ultimateStrength, hyperbolicParameters.strengthUnit).toLocaleString(undefined, {maximumFractionDigits: 2})}`}
            />
          }
          {hyperbolicParameters.strengthUnit === "psi" &&
            <ValueCell
              title={`Ultimate Strength (${unit})`}
              value={`${convertUltimateStrengthValue(hyperbolicParameters.ultimateStrength, hyperbolicParameters.strengthUnit).toLocaleString(undefined, {maximumFractionDigits: 0})}`}
            />
          }
        </>
      }
      {props.maturityMethod.type === "nurse saul" &&
        <>
          <ValueCell
            title={`Initial Maturity (º${props.unit.toUpperCase()}-hr)`}
            value={`${convertInitialMaturityValue(hyperbolicParameters.initialMaturity, props.unit).toLocaleString(undefined, {maximumFractionDigits: 0})}`}
          />
          <Divider />
          <ValueCell
            title="k-value"
            value={`${convertKValue(hyperbolicParameters.kValue, props.unit).toFixed(6)}`}
          />
          <Divider />
          {hyperbolicParameters.strengthUnit === "psi" &&
            <ValueCell
              title={`Ultimate Strength (${unit})`}
              value={`${convertUltimateStrengthValue(hyperbolicParameters.ultimateStrength, hyperbolicParameters.strengthUnit).toLocaleString(undefined, {maximumFractionDigits: 0})}`}
            />
          }
          {hyperbolicParameters.strengthUnit === "mpa" &&
            <ValueCell
              title={`Ultimate Strength (${unit})`}
              value={`${convertUltimateStrengthValue(hyperbolicParameters.ultimateStrength, hyperbolicParameters.strengthUnit).toLocaleString(undefined, {maximumFractionDigits: 2})}`}
            />
          }
        </>
      }
    </>
  )
}

export default HyperbolicDataView
