import { Box } from "@mui/system"
import React from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Button, IconButton, Typography } from "@mui/material"
import SettingsIcon from "@mui/icons-material/Settings"

interface Props {
  title: string
  backButtonTitle?: string
  onBack: () => void
  projectSettingsButtonTitle?: string
  onProjectSettingsClicked?: (event: React.MouseEvent<HTMLElement>) => void
  showPlacementSettingsButton?: boolean
  onPlacementSettingsClicked?: (event: React.MouseEvent<HTMLElement>) => void
}

const NavigationBarView = (props: Props) => {
  const hasRightButtons = (props.projectSettingsButtonTitle != undefined) || (props.showPlacementSettingsButton != undefined && props.showPlacementSettingsButton)

  return (
    <Box 
      display="flex" 
      flexDirection="row" 
      alignItems="center"
      sx={{
        borderBottom: "1px solid gray"
      }}
    >
      {props.backButtonTitle != undefined &&
        <Box flexGrow={0}>
          <Button onClick={props.onBack}>
            <ArrowBackIcon />
            <Typography>{props.backButtonTitle}</Typography>
          </Button>
        </Box>
      }
      <Box flexGrow={1} sx={{
        overflow: "hidden"
      }}>
        <Typography variant="h3" align="center" sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          textWrap: "nowrap",
          marginRight: hasRightButtons ? "0px" : "64px"
        }}>
          {props.title}
        </Typography>
      </Box>
      {props.showPlacementSettingsButton !== undefined && props.showPlacementSettingsButton &&
        <IconButton onClick={props.onPlacementSettingsClicked}>
          <SettingsIcon color="primary" />
        </IconButton>
      }
      {props.projectSettingsButtonTitle != undefined &&
        <Box flexGrow={0} sx={{
          marginRight: "12px"
        }}>
          <Button onClick={props.onProjectSettingsClicked} sx={{ backgroundColor: "none", padding: "0", ":hover": { background: "none"} }} disableRipple={true}>
            <Typography>{props.projectSettingsButtonTitle}</Typography>
          </Button>
        </Box>
      }
    </Box>
  )
}

export default NavigationBarView
