import { createTheme } from "@mui/material"

const theme = createTheme({
  typography: {
    fontFamily: [
      "Inter",
      "sans-serif"
    ].join(","),
    h1: {
      fontSize: "42px",
      fontWeight: "bold",
      lineHeight: "64px"
    },
    h2: {
      fontSize: "36px",
      fontWeight: "bold",
      lineHeight: "54px",
    },
    h3: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "48px",
    },
    h5: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "22px",
    },
    h6: {
      fontSize: "24px",
      fontWeight: "semibold",
      lineHeight: "32px",
    },
    body1: {
      fontSize: "16px",
      lineHeight: "20px"
    },
    subtitle1: {
      fontSize: "14px",
      fontWeight: "bold",
      color: "rgba(0, 0, 0, 0.3)"
    },
    subtitle2: {
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: 600,
      textTransform: "uppercase",
      color: "rgba(0, 0, 0, 0.5)"
    },
    caption: {
      fontSize: "11px",
      lineHeight: "11px",
      textTransform: "uppercase",
      color: "rgba(0, 0, 0, 0.5)"
    },
    button: {
    }
  },
  palette: {
    primary: {
      main: "#000099"
    },
    secondary: {
      main: "#F9F6F0",
      light: "#FFFFFF"
    },
    text: {
      primary: "#000",
      secondary: "#000"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "500px",
          padding: "6px 24px",
          fontWeight: "bold",
          fontSize: "14px"
        }
      }
    },
    MuiNativeSelect: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          borderRadius: "4px",
          padding: "8px",
          backgroundColor: "#F9F6F0",
        },
        select: {
          "&:focus": {
            background: "transparent"
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "#00000015",
          borderRadius: "100px",
          height: "32px",
          minHeight: "32px",
          margin: "16px 0px",
        },
        indicator: {
          display: "none"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          textTransform: "none",
          fontSize: "16px",
          height: "32px",
          minHeight: "32px",
          minWidth: "0px",
          "&.Mui-selected": {
            backgroundColor: "#000099",
            borderRadius: "100px",
            border: "none",
            color: "white",
            fontWeight: "bold",
          },
        },
      }
    }
  }
})

export default theme
