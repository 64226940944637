import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material"
import React from "react"

interface Props {
  chartingEnabled: boolean
  onChartPressed: (event: React.MouseEvent<HTMLElement>) => void
}

const ChartBottomBar = (props: Props) => {
  return (
    <AppBar position="relative" color="secondary" sx={{ top: "auto", bottom: 0 }}>
      <Toolbar>
        <Box sx={{
          width: "100%"
        }} display="flex" justifyContent="space-around" alignItems="center">
          <Button onClick={props.onChartPressed} disabled={!props.chartingEnabled}>
            <Typography>
              Chart
            </Typography>
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default ChartBottomBar