import React from "react"
import { Box, List, Typography } from "@mui/material"
import Placement from "../../../models/placement"
import { calculateStrength } from "../../../utilities/maturity"

interface Props {
  placement: Placement
}

const GoalView = (props: Props) => {
  if (props.placement.goals && props.placement.goals.length === 0) {
    return null
  }

  if (props.placement.maturityMethod.type === "disabled") {
    return null
  }

  return (
    <>
      <Typography variant="caption">
        Goals
      </Typography>
      <List sx={{
        padding: "0px 12px",
        marginTop: "12px",
        width: "100%"
      }}>
        {props.placement.goals.map(goal => {
          return (
            <Box key={goal.id} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{

            }}>
              <Typography key={goal.id} variant="body1">
                {goal.name}
              </Typography>
              {props.placement.maturityMethod.type !== "disabled" && props.placement.maturityMethod.curveFit.type !== "disabled" &&
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                  {props.placement.maturityMethod.curveFit.parameters.strengthUnit === "psi" &&
                    <Typography variant="body1" sx={{
                      color: "gray"
                    }}>
                      {`${calculateStrength(props.placement, goal.value)?.toLocaleString(undefined, {maximumFractionDigits: 0})} psi`}
                    </Typography>
                  }
                  {props.placement.maturityMethod.curveFit.parameters.strengthUnit === "mpa" &&
                    <Typography variant="body1" sx={{
                      color: "gray"
                    }}>
                      {`${calculateStrength(props.placement, goal.value)?.toLocaleString(undefined, {maximumFractionDigits: 2})} MPa`}
                    </Typography>
                  }
                  <Typography variant="body1" sx={{
                    color: "gray",
                    fontSize: "12px"
                  }}>
                    {props.placement.maturityMethod.type === "arrhenius" &&
                      `${goal.value.toLocaleString(undefined, {maximumFractionDigits: 0})} º${props.placement.maturityMethod.temperatureUnit.toUpperCase()}-hr`
                    }
                    {props.placement.maturityMethod.type !== "arrhenius" &&
                      `${(props.placement.maturityMethod.temperatureUnit === "c" ? goal.value : goal.value * 9/5).toLocaleString(undefined, {maximumFractionDigits: 0})} º${props.placement.maturityMethod.temperatureUnit.toUpperCase()}-hr`
                    }
                  </Typography>
                </Box>
              }
              {props.placement.maturityMethod.type !== "disabled" && props.placement.maturityMethod.curveFit.type === "disabled" &&
                <Typography variant="body1" sx={{
                  color: "gray"
                }}>
                  {props.placement.maturityMethod.type === "arrhenius" &&
                    `${goal.value.toLocaleString(undefined, {maximumFractionDigits: 0})} º${props.placement.maturityMethod.temperatureUnit.toUpperCase()}-hr`
                  }
                  {props.placement.maturityMethod.type !== "arrhenius" &&
                    `${(props.placement.maturityMethod.temperatureUnit === "c" ? goal.value : goal.value * 9/5).toLocaleString(undefined, {maximumFractionDigits: 0})} º${props.placement.maturityMethod.temperatureUnit.toUpperCase()}-hr`
                  }
                </Typography>
              }
            </Box>
          )
        })}
      </List>
    </>
  )
}

export default GoalView
