import React, { useCallback, useMemo, createContext, useContext } from "react"
import axios from "axios"
import { jwtDecode } from "jwt-decode"
 
type Props = { children: React.ReactNode } 
const DATABASE_CONNECTION = "Username-Password-Authentication"

interface Auth0Token {
  access_token: string,
  id_token: string,
  scope: string,
  expires_in: number,
  token_type: string,
  refresh_token: string
}

type Auth0TokenStorage = {
  token: Auth0Token
  created_at: number
}

type User = {
    iss?: string
    sub?: string
    aud?: string[] | string
    exp?: number
    nbf?: number
    iat?: number
    jti?: string
    email?: string
    identifier?: string
}

type AuthContextType = {
  loginWithUsernamePassword: (username: string, password: string) => Promise<void>,
  signUp: (username: string, password: string) => Promise<void>,
  resetPassword: (email: string) => Promise<void>,
  refreshToken: () => Promise<void>,
  getToken: () => Auth0TokenStorage | null,
  isAuthenticated: () => boolean,
  isTokenExpired: () => boolean,
  isLoading: boolean,
  logout: () => void,
  user: User | null
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export function AuthProvider({children}: Props) {
  const [isLoading, setIsLoading] = React.useState(false)

  const loginWithUsernamePassword = useCallback((username: string, password: string) => {
    setIsLoading(true)

    return axios({
      method: "POST",
      url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: {
        grant_type: "password",
        username: username,
        password: password,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        scope: "openid profile email offline_access",
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
        connection: DATABASE_CONNECTION
      }
    }).then((response) => {
      const storedToken: Auth0TokenStorage = {
        token: response.data,
        created_at: Date.now()
      }
      const token = JSON.stringify(storedToken)
      localStorage.setItem("token", token)

      if (response.status === 200) {
        window.location.href = "/"
      } else {
        console.error("Login failed")
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  const signUp = useCallback((username: string, password: string) => {
    setIsLoading(true)

    return axios({
      method: "POST",
      url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/signup`,
      headers: { "content-type": "application/json" },
      data: {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
        email: username,
        password: password,
        connection: DATABASE_CONNECTION
      }
    }).then((response) => {
      if (response.status === 200) {
        return loginWithUsernamePassword(username, password)
      } else {
        console.error("Sign up failed")
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  const isAuthenticated = useCallback(() => {
    const token = localStorage.getItem("token")
    return token !== null
  }, [])

  const getToken: (() => Auth0TokenStorage) = useCallback(() => {
    const token = localStorage.getItem("token")
    
    if (token === null) {
      return null
    }

    return JSON.parse(token)
  }, [])

  const logout = useCallback(() => {
    localStorage.removeItem("token")
    window.location.href = "/"
  }, [])

  const refreshToken = useCallback(() => {
    const token = getToken()
    if (token === null) {
      return Promise.resolve()
    }

    setIsLoading(true)

    console.log("renewing")

    return axios({
      method: "POST",
      url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: {
        grant_type: "refresh_token",
        refresh_token: token.token.refresh_token,
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
        scope: "openid userid profile offline_access email",
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || ""
      }
    }).then((response) => {
      const data = response.data
      data.refresh_token = token.token.refresh_token
      const storedToken: Auth0TokenStorage = {
        token: data,
        created_at: Date.now()
      }
      console.log("renewed")
      const newToken = JSON.stringify(storedToken)
      localStorage.setItem("token", newToken)
    }).catch((error) => {
      console.error(error)
      localStorage.removeItem("token")
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  const isTokenExpired = useCallback(() => {
    const token = getToken()
    if (token === null) {
      return true
    }
  
    return Date.now() - token.created_at > token.token.expires_in * 1000
  }, [])

  const user = useMemo(() => {
    const token = getToken()
    if (token === null) {
      return null
    }

    const decoded = jwtDecode(token.token.id_token) as User
    decoded.identifier = decoded.sub?.split("|")[1]
    return decoded
  }, [isAuthenticated])

  const resetPassword = useCallback((email: string) => {
    setIsLoading(true)

    return axios({
      method: "POST",
      url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
      headers: { "content-type": "application/json" },
      data: {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
        email: email,
        connection: DATABASE_CONNECTION
      }
    }).then((response) => {
      if (response.status === 200) {
        return
      } else {
        console.error("Reset password failed")
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  const value = useMemo(() => ({ 
    loginWithUsernamePassword, 
    signUp,
    resetPassword,
    refreshToken,
    getToken,
    isAuthenticated,
    isTokenExpired,
    isLoading,
    logout,
    user
  }),
  [isLoading])

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
} 

export const useAuth = () => useContext(AuthContext)