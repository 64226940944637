import { List, Typography } from "@mui/material"
import React from "react"
import MaturityMethod from "../../../models/maturitymethod"
import { capitalize } from "../../../utilities/stringcasing"
import ValueCell from "../../reusable/ValueCell"
import HyperbolicDataView from "./curvefit/HyperbolicDataView"
import LogarithmicDataView from "./curvefit/LogarithmicDataView"

interface Props {
  maturityMethod: MaturityMethod
}

const CurveFitView = (props: Props) => {
  if (props.maturityMethod.type === "disabled") {
    return (<></>)
  }
  return (
    <>
      <Typography variant="caption">
        Curve Fit
      </Typography>
      <Typography sx={{
        fontSize: "10px",
        color: "gray"
      }}>
        Values below can be found on the maturity report
      </Typography>
      <List sx={{
        padding: "0px 12px",
        marginTop: "12px"
      }}>
        <ValueCell 
          title="Curve-fit Model"
          value={capitalize(props.maturityMethod.curveFit.type)}
        />
        {props.maturityMethod.curveFit.type == "hyperbolic" &&
          <HyperbolicDataView 
            curveFit={props.maturityMethod.curveFit} 
            unit={props.maturityMethod.temperatureUnit}
            maturityMethod={props.maturityMethod}
          />
        }
        {(props.maturityMethod.curveFit.type == "log" || props.maturityMethod.curveFit.type == "ln") &&
          <LogarithmicDataView 
            curveFit={props.maturityMethod.curveFit} 
            unit={props.maturityMethod.temperatureUnit} 
          />
        }
      </List>
    </>
  )
}

export default CurveFitView
