import { Box, Dialog, SxProps, Typography } from "@mui/material"
import React from "react"
import Placement from "../../../models/placement"
import CurveFitView from "./CurveFitView"
import MaturityMethodView from "./MaturityMethodView"
import PlacementGeneralInfoView from "./PlacementGeneralInfoView"
import TemperatureLimitsView from "./TemperatureLimitsView"
import AlertContainerView from "./alerts/AlertContainerView"
import GoalView from "./GoalView"

interface Props {
  placement: Placement
  projectId: number
}

const PlacementSpecificationsView = (props: Props) => {
  const sx: SxProps = {
    "& .MuiDialog-container": {
      alignItems: "flex-start"
    },
    "& .MuiDialog-paper": {
      maxWidth: "400px"
    }
  }
  const [showingAlerts, setShowingAlerts] = React.useState(false)

  const handleClose = () => {
    setShowingAlerts(false)
  }
  return (
    <>
      <Box sx={{
        padding: "24px",
        maxWidth: "400px"
      }}>
        <Typography variant="h5">
          Specifications
        </Typography>
        <PlacementGeneralInfoView
          name={props.placement.name} 
          timeZone={props.placement.timeZone} 
          onAlertsClicked={() => {
            setShowingAlerts(true)
          }}
        />
        <TemperatureLimitsView temperatureLimit={props.placement.temperatureLimit} temperatureUnit={props.placement.temperatureUnit} />
        <MaturityMethodView maturityMethod={props.placement.maturityMethod} />
        <CurveFitView maturityMethod={props.placement.maturityMethod} />
        <GoalView placement={props.placement} />
      </Box>
      <Dialog 
        sx={sx} 
        maxWidth="sm" 
        fullWidth={false} 
        scroll="paper" 
        open={showingAlerts} 
        onClose={handleClose}
      >
        <AlertContainerView
          projectId={props.projectId}
          placementId={props.placement.id}
          onBack={() => {
            setShowingAlerts(false)
          }}
        />
      </Dialog>
    </>
  )
}

export default PlacementSpecificationsView
