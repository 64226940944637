import { Box, Dialog, DialogTitle, TextField, Typography } from "@mui/material"
import React, { useState } from "react"
import Project from "../../models/project"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { createNewProject, fetchProjects } from "../../network/network"
import { loadProjcetsSilently } from "../../store/project/projectSlice"
import LoadingButton from "@mui/lab/LoadingButton"
import { uniqueString } from "../../utilities/stringcasing"
import { logAddedProject } from "../../utilities/analytics"

interface Props {
  open: boolean,
  onClose: () => void,
  onProjectCreate: (project: Project) => void
}

const CreateProjectDialog = (props: Props) => {
  const projects = useAppSelector(state => state.project.projects)
  const [newProjectName, setNewProjectName] = useState("")
  const [isCreatingNewProject, setIsCreatingNewProject] = useState(false)
  const [errorCreatingNewProject, setErrorCreatingNewProject] = useState(false)
  const accessToken = useAppSelector(state => state.user.token)
  const dispatch = useAppDispatch()

  const createNewProjectAndSelect = () => {
    if (accessToken === null) {
      return
    }

    setIsCreatingNewProject(true)

    const projectName = uniqueString(newProjectName, projects.map((project) => project.name))

    let newProject: Project
    createNewProject(accessToken, projectName)
      .then((project) => {
        newProject = project
        logAddedProject()
        return fetchProjects(accessToken)
      })
      .then((allProjects) => {
        dispatch(loadProjcetsSilently(allProjects))
      })
      .then(() => {
        props.onProjectCreate(newProject)
        setIsCreatingNewProject(false)
      })
      .catch(() => {
        setErrorCreatingNewProject(true)
        setIsCreatingNewProject(false)
      })
  }

  return (
    <Dialog
      sx={{
        textAlign: "center"
      }}
      fullWidth={true}
      open={props.open}
      onClose={() => {
        if (isCreatingNewProject) { return }
        props.onClose()
      }}>
      <DialogTitle sx={{ fontSize: "large" }}>Create New Project</DialogTitle>
      <TextField
        value={newProjectName}
        onChange={(event) => setNewProjectName(event.target.value)}
        label="Project Name"
        variant="outlined"
        sx={{
          margin: "24px"
        }} />
      { errorCreatingNewProject && <Typography variant="body2" sx={{ color: "red" }}>There was an erorr creating project</Typography> }
      <Box sx={{ textAlign: "right" }}>
        <LoadingButton
          loading={isCreatingNewProject}
          onClick={createNewProjectAndSelect}
          variant="contained"
          disabled={newProjectName.length === 0 || isCreatingNewProject}
          sx={{
            maxWidth: "200px",
            margin: "24px",
            borderRadius: "25px",
          }}
        >
          <Typography variant="subtitle1" sx={{
            color: "white",
            padding: "0",
            textTransform: "none"
          }}>
          Create Project
          </Typography>
        </LoadingButton>
      </Box>
    </Dialog>
  )
}

export default CreateProjectDialog