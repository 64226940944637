import React, { useEffect } from "react"
import AppbarView from "./appbar/AppbarView"
import TemperatureAndMaturityMonitoringLandingView from "./TemperatureMonitoring/TemperatureAndMaturityMonitoringLandingView"
import { useLocation } from "react-router-dom"
import { logPageView } from "../utilities/analytics"
import { useAuth } from "./auth/AuthProvider"

const DesktopAppContainer = () => {
  const {
    logout
  } = useAuth()

  const location = useLocation()

  useEffect(() => {
    logPageView(location)
  }, [location])

  return (
    <>
      <AppbarView onSignOut={ logout } />
      <TemperatureAndMaturityMonitoringLandingView />
    </>
  )
}

export default DesktopAppContainer
