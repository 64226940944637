import { Typography, Box } from "@mui/material"
import React from "react"
import Project from "../../models/project"

interface Props {
  project: Project
  selected: boolean
}

const ProjectView = (props: Props) => {
  return (
    <Box>
      <Typography variant='h5' sx={{
        wordBreak: "break-word"
      }}>{props.project.name}</Typography>
      <Typography variant='caption'>{props.project.placements.length} placements</Typography>
    </Box>
  )
}

export default ProjectView