import { AppBar, Badge, IconButton, Menu, Popover } from "@mui/material"
import { ReactComponent as CompanyIcon } from "./app-icon.svg"
import React, { useEffect, useState } from "react"
import { AccountCircle, Mail } from "@mui/icons-material"
import { Box } from "@mui/system"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { fetchInvitations } from "../../network/network"
import { Invitation } from "../../models/invitation"
import InvitationListView from "../invitations/InvitationListView"
import { loadProjects } from "../../store/project/projectSlice"
import ProfileView from "../profile/ProfileView"
import { Link } from "react-router-dom"

interface Props {
  onSignOut: () => void
}

const AppbarView = (props: Props) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)
  const [invitationsMenuAnchorElement, setInvitationsMenuAnchorElement] = useState<null | HTMLElement>(null)
  const accessToken = useAppSelector(state => state.user.token)

  const [invitations, setInvitations] = useState<Invitation[]>([])

  useEffect(() => {
    if (!accessToken) {
      return
    }
    const loadInvitations = async () => {
      const result = await fetchInvitations(accessToken)
      setInvitations(result)
    }
    loadInvitations()
  }, [])

  const dispatch = useAppDispatch()

  return (
    <AppBar 
      color="inherit" 
      position="fixed" 
      elevation={1}
      sx={{
        padding: "8px 0px",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}>
      <Box flexDirection="row" justifyContent="space-between" display="flex" alignItems="center">
        <Link to="/">
          <CompanyIcon style={{
            height: "40px"
          }} />
        </Link>
        <Box>
          <IconButton 
            edge="end" 
            disabled={invitations.length == 0} 
            color="primary" 
            sx={{
              marginRight: "8px"
            }}
            onClick={(event) => {
              setInvitationsMenuAnchorElement(event.currentTarget)
            }}
          >
            <Badge badgeContent={invitations.length} color="error">
              <Mail />
            </Badge>
          </IconButton>
          <IconButton 
            edge="end" 
            sx={{
              marginRight: "8px"
            }}
            aria-controls="user-menu"
            aria-haspopup="true"
            color="primary"
            onClick={ (event: React.MouseEvent<HTMLElement>) => {
              setAnchorElement(event.currentTarget)
            }}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="user-menu"
            sx={{
              padding: 2
            }}
            anchorEl={anchorElement}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={Boolean(anchorElement)}
            onClose={() => {
              setAnchorElement(null)
            }}
          >
            <ProfileView onSignOut={props.onSignOut} />
          </Menu>
          <Popover
            id="invitations-menu"
            anchorEl={invitationsMenuAnchorElement}
            PaperProps={{
              elevation: 0,
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={Boolean(invitationsMenuAnchorElement) && invitations.length > 0}
            onClose={() => {
              setInvitationsMenuAnchorElement(null)
            }}
          >
            <InvitationListView 
              invitations={invitations} 
              didRespondToInvitation={(invitation: Invitation) => {
                setInvitations(invitations.filter((i) => i.id !== invitation.id))
                if (accessToken) {
                  dispatch(loadProjects(accessToken))
                }
              }}
            />
          </Popover>
        </Box>
      </Box>
    </AppBar>
  )
}

export default AppbarView
