import { Box, List, ListItem, ListItemButton, Tab, Tabs, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import Placement from "../../models/placement"
import PlacementView from "./PlacementView"
import { useParams } from "react-router-dom"
import { useAppSelector } from "../../store/hooks"

interface Props {
  onPlacementSelect: (placement: Placement) => void
}

const PlacementListView = (props: Props) => {
  const loadedProjects = useAppSelector(state => state.project.projects)
  const { projectId, placementId } = useParams()
  const [selectedTab, setSelectedTab] = useState(0)
  const [sortedPlacements, setSortedPlacements] = useState<Placement[]>([])
  
  if (!projectId) {
    console.log("No project ID in URL.")
    return (<></>)
  }
  const selectedProject = loadedProjects.find(p => p.id === parseInt(projectId))
  const placements = selectedProject?.placements ?? []
  let selectedPlacement: Placement | undefined = undefined
  if (placementId) {
    selectedPlacement = placements.find(p => p.id === parseInt(placementId))
  }

  const sortPlacements = () => {
    let placements = selectedProject?.placements.slice() ?? []
    if (selectedTab === 0) {
      placements = placements.sort((a: Placement, b: Placement) => {
        if (a.name < b.name) {
          return -1
        } else if (a.name > b.name) {
          return 1
        } else {
          return 0
        }
      })
    } else {
      placements = placements.sort((a: Placement, b: Placement) => {
        const aDate = Date.parse(a.updatedAt)
        const bDate = Date.parse(b.updatedAt)
        if (aDate > bDate) {
          return -1
        } else if (aDate < bDate) {
          return 1
        } else {
          return 0
        }
      })
    }
    setSortedPlacements(placements)
  }

  useEffect(() => {
    sortPlacements()
  }, [selectedTab])

  useEffect(() => {
    sortPlacements()
  }, [selectedProject])

  return (
    <Box sx={{
      height: "100%",
      width: "100%",
      overflow: "scroll"
    }}>
      <Typography variant="subtitle2" sx={{
        padding: "0",
        marginTop: "24px",
        marginLeft: "24px",
      }}>
        Placements
      </Typography>
      <Tabs 
        variant="fullWidth" 
        value={selectedTab} 
        onChange={(event, newValue) => setSelectedTab(newValue)}
        sx={{
          margin: "24px 24px 0px 24px"
        }}
      >
        <Tab label="Name" value={0} />
        <Tab label="Recent" value={1} />
      </Tabs>
      <List sx={{
        width: "100%",
        margin: "12px 0px 0px 0px",
      }}>
        {sortedPlacements.map((placement) => {
          return (
            <React.Fragment key={placement.id}>
              <ListItem alignItems="flex-start" sx={{
                padding: 0,
                margin: 0
              }}>
                <ListItemButton onClick={() => props.onPlacementSelect(placement) } sx={{
                  margin: "0px 0px 0px 0px",
                  padding: "10px 8px",
                  backgroundColor: selectedPlacement?.id === placement.id ? "#000099" : "white",
                  ":hover": {
                    backgroundColor: selectedPlacement?.id === placement.id ? "#000099" : "#F5F5F5",
                    color: selectedPlacement?.id === placement.id ? "white" : "inherit"
                  }
                }}>
                  <PlacementView 
                    selected={selectedPlacement?.id === placement.id} 
                    placement={placement} 
                    showSettings={selectedProject?.userInfo.permission !== "readonly"}
                    projectId={selectedProject?.id ?? 0}
                  />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          )
        })}
      </List>
    </Box>
  )
}

export default PlacementListView
