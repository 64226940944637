import React, { useEffect } from "react"
import { Box, CircularProgress, CssBaseline, ThemeProvider } from "@mui/material"
import theme from "./theme"
import { useAppDispatch, useAppSelector } from "../store/hooks"
import { setToken } from "../store/user/userSlice"
import { useMediaQuery } from "react-responsive"
import MobileAppContainer from "./MobileAppContainer"
import DesktopAppContainer from "./DesktopAppContainer"
import { BrowserRouter } from "react-router-dom"
import { loadProjects } from "../store/project/projectSlice"
import { associateUser } from "../utilities/analytics"
import SignIn from "./auth/SignIn"
import { useAuth } from "./auth/AuthProvider"
import { Helmet } from "react-helmet"

function App() {
  const dispatch = useAppDispatch()

  const loadingProjects = useAppSelector(state => state.project.loading)

  const isDesktop = useMediaQuery({
    query: "(min-width: 1050px)"
  })

  const {
    isAuthenticated,
    getToken,
    isTokenExpired,
    isLoading,
    user,
    refreshToken
  } = useAuth()

  useEffect(() => {
    const token = getToken()

    if (token === null || user === null) {
      return
    }

    if (!isAuthenticated() && isLoading) {
      return
    }

    if (isAuthenticated() && isTokenExpired()) {
      refreshToken()
      return
    }

    dispatch(setToken(token.token.access_token))
    dispatch(loadProjects(token.token.access_token))
    associateUser(user.sub ?? "", user.sub ?? "")
  }, [isAuthenticated(), isLoading, isTokenExpired()])

  if (loadingProjects || (isLoading && isAuthenticated())) {
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="space-around" 
        alignItems="center"
        sx={{
          height: "100vh",
          width: "100vw"
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Helmet>
          <meta name="viewport" content="width=device-width, user-scalable=no"/>
        </Helmet>
        {isAuthenticated() && isDesktop &&
          <Box sx={{
            height: "100%",
            overflow: "hidden"
          }}>
            <DesktopAppContainer />
          </Box>
        }
        {isAuthenticated() && !isDesktop &&
          <Box sx={{
            height: "100%",
            overflow: "hidden"
          }}>
            <MobileAppContainer />
          </Box>

        }
        {!isAuthenticated() &&
          <Box 
            display="flex" 
            flexDirection="column" 
            justifyContent="space-around" 
            alignItems="center"
            sx={{
              height: "100%",
              width: "100%",
              background: theme.palette.secondary.main,
              position: "relative",
              overflow: "scroll"
            }}
          >
            <SignIn />
          </Box>
        }
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
