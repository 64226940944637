export type Alert = {
  id: number
  projectId: number
  type: "minimum_temp" | "maximum_temp" | "minimum_temp_approach" | "maximum_temp_approach" | "temperature_difference" | "temperature_difference_approach" | "goal_reached"
  value: number
}

export const nameForAlertType = (type: "minimum_temp" | "maximum_temp" | "minimum_temp_approach" | "maximum_temp_approach" | "temperature_difference" | "temperature_difference_approach" | "goal_reached") : string => {
  switch(type) {
  case "minimum_temp":
    return "Minimum Temperature"
  case "maximum_temp":
    return "Maximum Temperature"
  case "minimum_temp_approach":
    return "Minimum Temperature Approach"
  case "maximum_temp_approach":
    return "Maximum Temperature Approach"
  case "temperature_difference":
    return "Temperature Difference"
  case "temperature_difference_approach":
    return "Temperature Difference Approach"
  case "goal_reached":
    return "Goal Reached"
  }
}