import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, styled, tableCellClasses } from "@mui/material"
import React from "react"
import Placement from "../../models/placement"
import { convertedDifferenceValue, convertedTemperatureValue } from "../../utilities/calculations"

interface Props {
  placement: Placement
  projectName: string
  id: string
}

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#AAAAAA",
  },
}))

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 6
  },
}))

const ProjectSummaryTableView = (props: Props) => {

  function createData(name: string, value: string | number) {
    return { name, value }
  }

  const thresholdMax = props.placement.temperatureLimit.maximumTemperature
  const convertedThresholdMax = thresholdMax ? convertedTemperatureValue(thresholdMax, props.placement.temperatureUnit) : "-"

  const thresholdMin = props.placement.temperatureLimit.minimumTemperature
  const convertedThresholdMin = thresholdMin ? convertedTemperatureValue(thresholdMin, props.placement.temperatureUnit) : "-"

  const thresholdDiff = props.placement.temperatureLimit.difference
  const convertedThresholdDiff = thresholdDiff ? convertedDifferenceValue(thresholdDiff, props.placement.temperatureUnit) : "-"


  const rows = [
    createData("Project Name", props.projectName),
    createData("Placement Name", props.placement.name),
    createData("Temperature Unit", props.placement.temperatureUnit === "c" ? "ºC" : "ºF"),
    createData("Time Zone", props.placement.timeZone),
    createData("Temperature Threshold Maximum", convertedThresholdMax),
    createData("Temperature Threshold Minimum", convertedThresholdMin),
    createData("Temperature Difference Limit", convertedThresholdDiff)
  ]

  return (
    <TableContainer id={props.id} component={Paper} sx={{
      width: "80%",
      overflow: "hidden"
    }}>
      <Table sx={{
        border: "2px solid black"
      }}>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row" sx={{
                backgroundColor: "#7F7F7F",
                borderRight: "1px solid black",
              }}>
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.value}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ProjectSummaryTableView