import { Box, CircularProgress, Divider, List, ListItem, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { useAppSelector } from "../../../../store/hooks"
import { fetchAlerts } from "../../../../network/network"
import { Alert } from "../../../../models/alert"
import AlertSettingSection from "./AlertSettingSection"
import { convertedDifferenceValue, convertedTemperatureValue } from "../../../../utilities/calculations"

interface Props {
  projectId: number
  placementId: number
}

const AlertsView = (props: Props) => {
  const loadedProjects = useAppSelector(state => state.project.projects)
  const selectedProject = loadedProjects.find(p => p.id === props.projectId)
  const selectedPlacement = selectedProject?.placements.find(p => p.id === props.placementId)
  const [alerts, setAlerts] = React.useState<Alert[]>([])
  const accessToken = useAppSelector(state => state.user.token)
  const [loading, setLoading] = React.useState(false)
  if (!selectedPlacement) {
    return (
      <></>
    )
  }

  useEffect(() => {
    const loadAlertsFromNetwork = async () => {
      if (!accessToken) {
        return
      }
      setLoading(true)
      const alerts = await fetchAlerts(accessToken, props.projectId, props.placementId)
      setAlerts(alerts)
      setLoading(false)
    }
    loadAlertsFromNetwork()
  }, [])

  if (loading) {
    return (
      <Box 
        display="flex" 
        alignItems="center" 
        flexDirection="column"
        sx={{ padding: "12px" }}
      >
        <CircularProgress/>
      </Box>
    )
  }

  const minimumTempAlert = alerts.filter(a => a.type === "minimum_temp")
  const minimumApproachAlert = alerts.filter(a => a.type === "minimum_temp_approach")
  const maximumTempAlert = alerts.filter(a => a.type === "maximum_temp")
  const maximumApproachAlert = alerts.filter(a => a.type === "maximum_temp_approach")
  const tempDifferenceAlert = alerts.filter(a => a.type === "temperature_difference")
  const tempDifferenceApproachAlert = alerts.filter(a => a.type === "temperature_difference_approach")
  const goalReachedAlert = alerts.filter(a => a.type === "goal_reached")

  return (
    <Box display="flex" flexDirection="column">
      <AlertSettingSection
        mainAlertTitle="Minimum Temperature"
        mainAlertEnabled={minimumTempAlert.length > 0}
        mainAlertDescription="When any sensor in this placement gets a reading below this temperature, an alert will be triggered"
        mainAlertValue={convertedTemperatureValue(selectedPlacement.temperatureLimit.minimumTemperature ?? 0, selectedPlacement.temperatureUnit) + `º${selectedPlacement.temperatureUnit.toUpperCase()}`}
        secondaryAlertTitle="Approach Warning"
        secondaryAlertEnabled={minimumApproachAlert.length > 0}
        secondaryAlertDescription="Trigger an alert when the minimum temperature in this placement gets within this value of the minimum temperature alert"
        secondaryAlertValue={convertedDifferenceValue(minimumApproachAlert.length > 0 ? minimumApproachAlert[0].value : 0, selectedPlacement.temperatureUnit) + `º${selectedPlacement.temperatureUnit.toUpperCase()}`}
      />
      <Divider sx={{
        marginTop: "12px"
      }} />
      <AlertSettingSection
        mainAlertTitle="Maximum Temperature"
        mainAlertEnabled={maximumTempAlert.length > 0}
        mainAlertDescription="When any sensor in this placement gets a reading above this temperature, an alert will be triggered"
        mainAlertValue={convertedTemperatureValue(selectedPlacement.temperatureLimit.maximumTemperature ?? 0, selectedPlacement.temperatureUnit) + `º${selectedPlacement.temperatureUnit.toUpperCase()}`}
        secondaryAlertTitle="Approach Warning"
        secondaryAlertEnabled={maximumApproachAlert.length > 0}
        secondaryAlertDescription="Trigger an alert when the maximum temperature in this placement gets within this value of the maximum temperature"
        secondaryAlertValue={convertedDifferenceValue(maximumApproachAlert.length > 0 ? maximumApproachAlert[0].value : 0, selectedPlacement.temperatureUnit) + `º${selectedPlacement.temperatureUnit.toUpperCase()}`}
      />
      <Divider sx={{
        marginTop: "12px"
      }} />
      <AlertSettingSection
        mainAlertTitle="Temperature Difference"
        mainAlertEnabled={tempDifferenceAlert.length > 0}
        mainAlertDescription="When the temperature difference in this placement surpasses this value, an alert will be triggered"
        mainAlertValue={convertedDifferenceValue(selectedPlacement.temperatureLimit.difference ?? 0, selectedPlacement.temperatureUnit) + `º${selectedPlacement.temperatureUnit.toUpperCase()}`}
        secondaryAlertTitle="Approach Warning"
        secondaryAlertEnabled={tempDifferenceApproachAlert.length > 0}
        secondaryAlertDescription="Trigger an alert when the temperature difference in this placement gets within this value of the temperature difference limit"
        secondaryAlertValue={convertedDifferenceValue(tempDifferenceApproachAlert.length > 0 ? tempDifferenceApproachAlert[0].value : 0, selectedPlacement.temperatureUnit) + `º${selectedPlacement.temperatureUnit.toUpperCase()}`}
      />
      {selectedPlacement.maturityMethod.type !== "disabled" && selectedPlacement.goals.length > 0 &&
        <>
          <Divider sx={{
            marginTop: "12px"
          }} />
          <List 
            sx={{
              width: "100%",
              padding: "0px",
              margin: "0px"
            }}
          >
            <ListItem>
              <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                width: "100%",
                padding: 0,
                margin: "12px 0px 0px 0px"
              }}>
                <Typography variant="body1" sx={{fontWeight: "bold"}}>
                  Goal Reached
                </Typography>
                <Typography variant="body1" color={goalReachedAlert.length > 0 ? "green" : "gray"} sx={{
                  fontWeight: goalReachedAlert.length > 0 ? "bold" : "normal"
                }}>
                  {goalReachedAlert.length > 0 ? "ON" : "OFF"}
                </Typography>
              </Box>
            </ListItem>
          </List>
        </>
      }
    </Box>
  )
}

export default AlertsView
