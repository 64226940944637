import { Box } from "@mui/material"
import React from "react"
import Placement from "../../models/placement"
import SensorListContainerView from "../sensors/SensorListContainerView"
import { useParams } from "react-router-dom"
import { useAppSelector } from "../../store/hooks"
import moment from "moment"


const PlacementDetailsContainerView = () => {
  const loadedProjects = useAppSelector(state => state.project.projects)
  const { projectId, placementId } = useParams()
  if (!projectId) {
    console.log("No project ID in URL.")
    return (<></>)
  }
  const selectedProject = loadedProjects.find(p => p.id === parseInt(projectId))
  if (selectedProject === undefined) {
    console.log("Project not found.")
    return (<></>)
  }
  const placements = loadedProjects.find(p => p.id === parseInt(projectId))?.placements ?? []
  let selectedPlacement: Placement | undefined = undefined
  if (placementId) {
    selectedPlacement = placements.find(p => p.id === parseInt(placementId))
    if (selectedPlacement) {
      moment.tz.setDefault(selectedPlacement.timeZone)
    }
  }
  
  return (
    <Box display="flex" flexDirection="row" flex={1} sx={{
      backgroundColor: "#F9F6F0",
      overflow: "hidden"
    }}>
      <Box display="flex" flexGrow={1} sx={{
        overflow: "hidden",
      }}>
        {selectedPlacement !== undefined && 
          <SensorListContainerView />
        }
      </Box>
    </Box>
  )
}

export default PlacementDetailsContainerView
