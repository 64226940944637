import { Divider } from "@mui/material"
import React from "react"
import CurveFit, { LogarithmicParameters } from "../../../../models/curvefit"
import SampleUnit from "../../../../models/units"
import ValueCell from "../../../reusable/ValueCell"
import { convertCurveFitIntercept, convertSlope } from "../../../../utilities/calculations"

interface Props {
  curveFit: CurveFit
  unit: SampleUnit
}

const LogarithmicDataView = (props: Props) => {
  const logParameters = props.curveFit.parameters as LogarithmicParameters

  let unit = ""
  if (props.curveFit.parameters.strengthUnit === "mpa") {
    unit = "MPa"
  } else {
    unit = "psi"
  }

  const intercept = convertCurveFitIntercept(logParameters.intercept, props.unit, logParameters.strengthUnit, props.curveFit.type, logParameters.slope)
  let interceptDisplay = ""
  if (logParameters.strengthUnit === "mpa") {
    interceptDisplay = intercept.toFixed(2)
  } else {
    interceptDisplay = `${Math.round(intercept)}`
  }

  const slope = convertSlope(logParameters.slope, logParameters.strengthUnit)
  let slopeDisplay = ""
  if (logParameters.strengthUnit === "mpa") {
    slopeDisplay = slope.toFixed(2)
  } else {
    slopeDisplay = slope.toFixed(1)
  }

  return (
    <>
      <Divider />
      <ValueCell
        title="Strength Unit"
        value={unit}
      />
      <Divider />
      <ValueCell
        title={`Intercept (${unit})`}
        value={interceptDisplay}
      />
      <Divider />
      <ValueCell
        title={`Slope (${unit}/º${props.unit.toUpperCase()}-hr)`}
        value={slopeDisplay}
      />
    </>
  )
}

export default LogarithmicDataView