import React, { useEffect } from "react"
import AppbarView from "./appbar/AppbarView"
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import ProjectLoadingContainer from "./projects/ProjectLoadingContainer"
import { Box, Typography } from "@mui/material"
import MobilePlacementListScreen from "./mobile/MobilePlacementListScreen"
import MobileSensorListScreen from "./mobile/MobileSensorListScreen"
import { logPageView } from "../utilities/analytics"
import { useAuth } from "./auth/AuthProvider"

const MobileAppContainer = () => {
  const navigate = useNavigate()

  const {
    logout
  } = useAuth()

  const location = useLocation()

  useEffect(() => {
    logPageView(location)
  }, [location])

  return (
    <Box sx={{
      height: "100dvh",
      padding: 0
    }}>
      <Routes>
        <Route path="/projects/:projectId/placements/:placementId" element={ <MobileSensorListScreen /> } />
        <Route path="/projects/:projectId" element={ <MobilePlacementListScreen /> } />
        <Route path="/" element={
          <>
            <AppbarView onSignOut={ logout } />
            <Box sx={{
              marginTop: "64px",
              textAlign: "center",
              borderBottom: "1px solid rgba(0,0,0,0.3)"
            }}>
              <Typography sx={{
                fontSize: "18px",
                padding: "16px",
                fontWeight: "bold"
              }}>COMMAND Center Concrete</Typography>
            </Box>
            <Box sx={{  
              height: "calc(100dvh - 116px)"
            }}>
              <ProjectLoadingContainer onProjectSelect={(project) => {
                navigate(`/projects/${project.id}`)
              }} />
            </Box>
          </>
        } />
        <Route path="*" element={
          <Navigate to="/" />
        }/>
      </Routes>
    </Box>
  )
}
export default MobileAppContainer
