import React from "react"
import Sensor from "../../../models/sensor"
import { convertedTemperatureValue } from "../../../utilities/calculations"
import { highestSampleForSensor, lowestSampleForSensor } from "../../../utilities/samples"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from "@mui/material"
import SampleUnit from "../../../models/units"
import moment from "moment"

interface Props {
  id: string
  sensors: Sensor[]
  timezone: string
  temperatureUnit: SampleUnit
}

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#AAAAAA",
  },
}))

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#7F7F7F"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 6
  },
  "&:not(:last-child)": {
    borderRight: "1px solid black"
  }
}))

const MaximumAndMinimumSensorsTableView = (props: Props) => {
  function createData(name: string, serialNumber: string, minimumTemperature: number, minimumTemperatureTime: string, maximumTemperature: number, maximumTemperatureTime: string) {
    return { name, serialNumber, minimumTemperature, minimumTemperatureTime, maximumTemperature, maximumTemperatureTime }
  }

  interface Row {
    name: string
    serialNumber: string
    minimumTemperature: number
    minimumTemperatureTime: string
    maximumTemperature: number
    maximumTemperatureTime: string
  }
  const rows: Row[] = []
  props.sensors.forEach((sensor: Sensor) => {
    const minSample = lowestSampleForSensor(sensor)
    const maxSample = highestSampleForSensor(sensor)
    if (!minSample || !maxSample) {
      return
    }
    rows.push(createData(
      sensor.name && sensor.name.length > 0 ? sensor.name : "-", 
      sensor.serial, 
      parseFloat(convertedTemperatureValue(minSample.temperature, props.temperatureUnit)), 
      minSample?.time ? moment(minSample.time).format("MM/DD/YY HH:mm") : "", 
      parseFloat(convertedTemperatureValue(maxSample.temperature, props.temperatureUnit)), 
      maxSample?.time ? moment(maxSample.time).format("MM/DD/YY HH:mm") : "",
    ))
  })

  return (
    <TableContainer id={props.id} component={Paper} sx={{
      width: "100%"
    }}>
      <Table sx={{
        border: "2px solid black"
      }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              Name
            </StyledTableCell>
            <StyledTableCell>
              Serial Number
            </StyledTableCell>
            <StyledTableCell>
              {`Min Temp (${props.temperatureUnit === "c" ? "ºC" : "ºF"})`}
            </StyledTableCell>
            <StyledTableCell>
              Min Temp Time
            </StyledTableCell>
            <StyledTableCell>
              {`Max Temp (${props.temperatureUnit === "c" ? "ºC" : "ºF"})`}
            </StyledTableCell>
            <StyledTableCell>
              Max Temp Time
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.serialNumber}>
              <StyledTableCell align="right">{row.name}</StyledTableCell>
              <StyledTableCell align="right">{row.serialNumber}</StyledTableCell>
              <StyledTableCell align="right">{row.minimumTemperature}</StyledTableCell>
              <StyledTableCell align="right">{row.minimumTemperatureTime}</StyledTableCell>
              <StyledTableCell align="right">{row.maximumTemperature}</StyledTableCell>
              <StyledTableCell align="right">{row.maximumTemperatureTime}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MaximumAndMinimumSensorsTableView
