import React, { useEffect, useState } from "react"
import { Box, CircularProgress, IconButton, InputBase, List, Typography } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { archiveProject, fetchInvitationsForProject, fetchProjectParticipantList, fetchProjects, unarchiveProject, updateProject } from "../../network/network"
import Participant from "../../models/participant"
import ProjectParticipantView from "./ProjectParticipantView"
import { Invitation } from "../../models/invitation"
import { loadProjcetsSilently } from "../../store/project/projectSlice"
import { LoadingButton } from "@mui/lab"
import Project from "../../models/project"
import { AddCircle } from "@mui/icons-material"
import AddParticipantDialog from "./AddParticipantDialog"
import { logProjectArchived, logProjectUnarchived } from "../../utilities/analytics"

interface Props {
  project: Project
}

export type ProjectParticipant = {
  userId: string | null
  email: string
  participation: string
}

export const fetchAllProjectParticipants = async (accessToken: string, projectId: number) : Promise<ProjectParticipant[]> => {
  const participants = await fetchProjectParticipantList(accessToken, projectId)
  const invitations = await fetchInvitationsForProject(accessToken, projectId)

  const projectParticipants: ProjectParticipant[] = participants.map((participant: Participant) => {
    return {
      userId: participant.userId,
      email: participant.email,
      participation: participant.permission
    }
  })

  const pendingParticipants = invitations.map((invitation: Invitation) => {
    return {
      userId: null,
      email: invitation.email,
      participation: "pending"
    }
  })

  return projectParticipants.concat(pendingParticipants)
}

const ProjectSettingsView = (props: Props) => {
  const accessToken = useAppSelector(state => state.user.token)
  const [loading, setLoading] = useState(false)
  const [projectName, setProjectName] = useState(props.project.name)
  const [errorUpdatingProjectName, setErrorUpdatingProjectName] = useState(false)
  const [updatingProject, setUpdatingProject] = useState(false)
  const [participants, setParticipants] = useState<ProjectParticipant[]>([])
  const [updatingProjectUserInfo, setUpdatingProjectUserInfo] = useState(false)
  const [newParticipantDialogOpen, setNewParticipantDialogOpen] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!accessToken) {
      console.log("No access token.")
      return
    }

    setLoading(true)

    const fetchProjectsFromNetwork = async () =>  {
      const participants = await fetchAllProjectParticipants(accessToken, props.project.id)
      setParticipants(participants)
      setLoading(false)
    }

    fetchProjectsFromNetwork()
  }, [accessToken])
  
  const updateProjectName = async () => {
    if (!accessToken) {
      console.log("No access token.")
      return
    }

    setUpdatingProject(true)

    setErrorUpdatingProjectName(false)

    try {
      await updateProject(accessToken, props.project.id, projectName)
      const allProjects = await fetchProjects(accessToken)
      dispatch(loadProjcetsSilently(allProjects))
    } catch (error) {
      setErrorUpdatingProjectName(true)
    }

    setUpdatingProject(false)
  }

  const toggleProjectArchive = async () => {
    if (!accessToken) {
      console.log("No access token.")
      return
    }

    setUpdatingProjectUserInfo(true)

    try {
      if (props.project.userInfo.isArchived) {
        await unarchiveProject(accessToken, props.project.id)
        logProjectUnarchived(props.project.id)
      } else {
        await archiveProject(accessToken, props.project.id)
        logProjectArchived(props.project.id)
      }
      const allProjects = await fetchProjects(accessToken)
      dispatch(loadProjcetsSilently(allProjects))
    } catch (error) {
      alert("Error updating project archive status")
    }

    setUpdatingProjectUserInfo(false)
  }

  const isUserReadOnly = props.project.userInfo.permission === "readonly"

  return (
    <Box display="flex" flexDirection="column" alignItems="left" sx={{
      minWidth: "315px",
      padding: "0px 0px 12px 0px",
      margin: "16px"
    }} >
      <Box sx={{
        width: "100%", 
        textAlign: "right",
        marginBottom: "16px"
      }}>
        <LoadingButton 
          loading={updatingProjectUserInfo}
          color={props.project.userInfo.isArchived ? "primary" : "error"} 
          sx={{ 
            textTransform: "none",
            fontWeight: "normal",
            background: "none",
            padding: "0px",
            ":hover": { background: "none" }
          }}
          onClick={toggleProjectArchive}
        >
          {props.project.userInfo.isArchived ? "Unarchive" : "Archive"} Project
        </LoadingButton>
      </Box>
      <Typography variant="subtitle2">
        Project Name
      </Typography>
      <Box 
        display="flex" 
        flexDirection="row" 
        alignItems="center" 
        justifyContent="space-between"
        sx={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          height: "32px",
          padding: "0px 0px 0px 12px",
          width: "100%",
          margin: "16px 0px 0px 0px"
        }}
      >
        <InputBase 
          placeholder="Enter project name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          disabled={updatingProject || isUserReadOnly}
        />
        {!isUserReadOnly &&
        <LoadingButton color="primary" sx={{
          backgroundColor: "rgba(0, 0, 0, 0.08);",
          padding: "0px",
          height: "100%",
          textTransform: "none",
          color: "black",
          borderRadius: "0px",
          fontWeight: "normal"
        }}
        loading={updatingProject}
        disabled={updatingProject}
        onClick={updateProjectName}
        >
          Save
        </LoadingButton>
        }
      </Box>      
      <Typography color="error" sx={{ marginBottom: "24px", marginTop: "12px" }}>
        {errorUpdatingProjectName && "Error updating project name" }
      </Typography>
      <Typography variant="subtitle2">
        Participants
      </Typography>
      {loading &&
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress sx={{
            margin: "20px"
          }} />
        </Box>
      }
      <List sx={{
        width: "100%"
      }}>
        {participants.map((participant: ProjectParticipant) => {
          return (
            <ProjectParticipantView 
              project={props.project} 
              participant={participant} 
              key={participant.email}
              onRemove={(participants) => {
                setParticipants(participants)
              }}
            />
          )
        })}
      </List>
      {!loading && !isUserReadOnly &&
      <Box sx={{ textAlign: "center" }}>
        <IconButton 
          size="small" 
          color="primary" 
          sx={{ width: "40px", height: "40px" }}
          onClick={() => setNewParticipantDialogOpen(true)}
        >
          <AddCircle sx={{ fontSize: "40px" }}/>
        </IconButton>
        <AddParticipantDialog 
          participants={participants}
          projectId={props.project.id}
          open={newParticipantDialogOpen}
          onClose={(participants) => {
            if (participants) {
              setParticipants(participants)
            }

            setNewParticipantDialogOpen(false)
          }
          }
        />
      </Box>
      } 
    </Box>
  )
}

export default ProjectSettingsView