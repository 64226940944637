import { List, ListItem, ListItemButton } from "@mui/material"
import React from "react"
import Project from "../../models/project"
import ProjectView from "./ProjectView"
import { useParams } from "react-router-dom"

interface Props {
  projects: Project[]
  onProjectSelect: (project: Project) => void
}

const ProjectListView = (props: Props) => {
  const { projectId } = useParams()
  const selectedProject = projectId === undefined ? undefined : props.projects.find(p => p.id === parseInt(projectId))
  return (
    <List>
      {props.projects.map((project) => {
        return ( 
          <ListItem key={project.id} sx={{
            padding: 0,
            margin: 0,
            width: "100%",
            overflow: "clip"
          }}>
            <ListItemButton onClick={() => props.onProjectSelect(project) } sx={{
              margin: 0,
              padding: "10px 32px"
            }}>
              <ProjectView
                selected={selectedProject?.id == project.id}
                project={project} 
              />
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}

export default ProjectListView