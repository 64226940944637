import { Box, IconButton, Popover, Tooltip, Typography } from "@mui/material"
import React, { useState } from "react"
import Placement from "../../models/placement"
import SettingsIcon from "@mui/icons-material/Settings"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import PlacementSpecificationsView from "./specifications/PlacementSpecificationsView"


interface Props {
  placement: Placement
  selected: boolean
  showSettings: boolean
  projectId: number
}

const PlacementView = (props: Props) => {

  const [placementSpecsAnchorElement, setPlacementSpecAnchorElement] = useState<HTMLElement | null>(null)

  const togglePopover = (event: React.MouseEvent<HTMLElement>) => {
    setPlacementSpecAnchorElement(event.currentTarget)
  }

  const settingsButton = () => {
    return (
      <IconButton 
        sx={{
          color: "white",
          margin: 0,
          padding: 0,
          width: "32px",
          height: "32px",
          "&.Mui-disabled": {
            color: "#ffffff44"
          }
        }}
        onClick={togglePopover}
        disabled={!props.showSettings}
      >
        <SettingsIcon />
      </IconButton>
    )
  }

  const popoverIsOpen = Boolean(placementSpecsAnchorElement)
  const popoverId = popoverIsOpen ? "placement-specs" : undefined

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{
      color: props.selected ? "white" : "black",
      width: "100%"
    }}>
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="flex-start"
        sx={{
          padding: "0px 24px"
        }}
      >
        <Typography variant="h5" sx={{
          wordBreak: "break-word"
        }}>{props.placement.name}</Typography>
        <Typography variant="caption" sx={{
          marginTop: "6px",
          color: props.selected ? "white" : ""
        }}>{props.placement.sensors.length} sensors</Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        {props.selected && props.showSettings == false &&
          <Tooltip title="You have limited access to this placement">
            <span>
              {settingsButton()}
            </span>
          </Tooltip>
        }
        {props.selected && props.showSettings &&
          settingsButton()
        }
        {!props.selected &&
          <Box sx={{
            width: "32px",
            heitht: "32px"
          }} />
        }
        <ArrowForwardIosIcon />
      </Box>
      <Popover 
        id={popoverId}
        open={popoverIsOpen}
        anchorEl={placementSpecsAnchorElement}
        onClose={() => { setPlacementSpecAnchorElement(null) }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
      >
        <PlacementSpecificationsView 
          placement={props.placement} 
          projectId={props.projectId}
        />
      </Popover>
    </Box>
  )
}

export default PlacementView
