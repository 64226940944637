import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"
import React, { useMemo } from "react"

interface Props {
    title: string,
    type: string,
    value: string,
    onChange: (value: string) => void
}

export default function StyledInput(props: Props) {
  const isPasswordField = props.type === "password"
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const fieldType = useMemo(() => {
    if (isPasswordField) {
      return showPassword ? "text" : "password"
    } else {
      return "text"
    }
  }, [showPassword])

  return (
    <Box display="flex" flexDirection="row" sx={{
      background: "white",
      borderRadius: "50px",
      overflow: "hidden",
      border: "1px solid gray",
    }}>
      <Box flexDirection="column" sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "lightgrey",
        width: "140px",
        borderRight: "1px solid gray"
      }}>
        <Typography variant="subtitle2" sx={{ 
        }}>
          {props.title}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" sx={{ width: "100%" }}>
        <input
          type={fieldType}
          name={props.title}
          id={props.title}
          autoComplete={props.type === "password" ? "current-password" : "username"}
          style={{
            border: "none",
            height: "35px",
            background: "none",
            paddingLeft: "10px",
            outline: "none",
            width: "100%",
            fontSize: "16px"
          }}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
        {isPasswordField &&
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            edge="end"
            size="small"
            sx={{
              paddingRight: "10px"
            }}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        }
      </Box>
    </Box>
  )
}